<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title m-auto fw-bold" [innerHTML]="t('user/getPremium')"></h2>
    <button (click)="activeModal.dismiss(null)" aria-label="Close" class="btn-close"><span aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body d-flex justify-content-end">
    <div class="p-4">
      <h4 class="text-primary fw-bold">{{ t('shared/licenseInfo') }}</h4>
      <p class="mb-1">{{ t('shared/licenseDetails') }}</p>
      <ul class="ms-5">
        <li
          *ngFor="
            let detail of [
              t('shared/licenseDetails1'),
              t('shared/licenseDetails2'),
              t('shared/licenseDetails3'),
              t('shared/licenseDetails4'),
              t('shared/licenseDetails5')
            ]
          "
        >
          {{ detail }}
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row modal-footer-row-normal me-6">
      <div class="modal-footer-right">
        <button (click)="onActivateCode()" type="button" class="btn btn-secondary">{{ t('user/submitActivationCode') }}</button>
        <button (click)="onGoToEshop()" type="button" class="ms-5 btn btn-primary ga-e-shop">{{ t('shared/goToShop') }}</button>
      </div>
    </div>
  </div>
</ng-container>
