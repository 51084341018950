/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `gdpr` - gdpr * `license` - license * `marketing` - marketing
 */
export type ContractTypeEnum = 'gdpr' | 'license' | 'marketing';

export const ContractTypeEnum = {
    Gdpr: 'gdpr' as ContractTypeEnum,
    License: 'license' as ContractTypeEnum,
    Marketing: 'marketing' as ContractTypeEnum
};