// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { commonEnvironment } from './environment.common';

import { LoggingLevel } from '@isophi/core-legacy';

export const environment = {
  envShortcut: 'D',
  production: false,
  logLevel: LoggingLevel.DEBUG,
  common: commonEnvironment,
  client_id: 'Ztq55vyUdTl7PbIWlaPu25ZVRr19TXGdaDQvxGX5',
  grant_type: 'password',
  recaptcha_site_key: '6LfI2tgUAAAAALWRGxPV2_5pAJ-JgMeCMDn8VSLv',
  ga_measurement_id: '',
  gtm_id: '',
  i18n: true,
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  api: 'https://api.isophi-merged.doc.endevel.cz/parent/api/v1',
  parentApiBase: 'https://api.isophi-merged.doc.endevel.cz/parent',
  authAPI: 'https://api.isophi-auth.doc.endevel.cz/api/v1',
  licenseAPI: 'https://api.isophi-merged.doc.endevel.cz/license/api/v1',
  materialServer: 'https://api.isophi-material.doc.endevel.cz',
  contractServer: 'https://api.isophi-merged.doc.endevel.cz/license',
  questionnaireApplicationUrl: 'https://isophi-questionnaire-app.doc.endevel.cz',
  pdfApiBase: 'https://api.isophi-pdf.doc.endevel.cz',
  sentryDSN: 'https://044070a56e3948c98088d110dcaa83d1@o1002766.ingest.sentry.io/4505052516122624',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
