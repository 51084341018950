/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `0-2` - 0-2 let * `2-5` - 2-5 let * `6-10` - 6-10 let * `11-15` - 11-15 let * `16+` - 16+ let
 */
export type LengthOfPracticeEnum = '0-2' | '2-5' | '6-10' | '11-15' | '16+';

export const LengthOfPracticeEnum = {
    _02: '0-2' as LengthOfPracticeEnum,
    _25: '2-5' as LengthOfPracticeEnum,
    _610: '6-10' as LengthOfPracticeEnum,
    _1115: '11-15' as LengthOfPracticeEnum,
    _16: '16+' as LengthOfPracticeEnum
};