<div class="polar-graph d-flex flex-column align-items-start" [class]="{ puppeteer: puppeteerApp }">
  <canvas
    *ngIf="imageArr.length > 0"
    baseChart
    [datasets]="polarAreaChartData"
    [legend]="false"
    [type]="polarAreaChartType"
    [options]="chartOptions"
    [plugins]="plugins"
    [width]="puppeteerApp && '600'"
  >
  </canvas>
  <div>
    <ng-content></ng-content>
  </div>
</div>
