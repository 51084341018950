import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import { Category, EvaluationRange } from '@isophi/parents-api';
import { Legend } from 'isophi-pdfs-api';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'isophi-basic-pie-chart',
  templateUrl: './basic-pie-chart.component.html',
  styleUrls: ['./basic-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicPieChartComponent implements OnInit, OnChanges {
  @Input() categoryResults!: {
    category: Category;
    evaluation: {
      title: string;
      code: number;
      textColor: string;
      backgroundColor: string;
    };
    maxScore: string;
    score: string;
    percent: string;
  }[];

  @Input() evaluations!: (EvaluationRange | Legend)[];

  @Input() lock?: boolean;

  public pieChartType: ChartType = 'pie';

  public pieChartData!: ChartData<'pie'>;

  public chartOptions!: ChartOptions<'pie'>;

  public ngOnInit() {
    Chart.register(ChartDataLabels);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryResults'] && changes['evaluations'] && this.evaluations && this.categoryResults) {
      if (this.lock) {
        this.evaluations = [...this.evaluations];
        this.evaluations.push({
          code: -1,
          backgroundColor: '#a3a3a3',
          id: 50,
          maxPercent: 0,
          maxPoint: 0,
          minPercent: 0,
          minPoint: 0,
          textColor: '#000000',
          title: 'none',
          imageUrl: 'none',
        });
      }
      this.createPieChart();
    }
  }

  public createPieChart(): void {
    const evaluationData: number[] = [];
    const backgroundColors: string[] = [];

    this.evaluations.forEach((evaluation) => {
      const filteredCategories = this.categoryResults.filter((category) => category.evaluation.code === evaluation.code);
      const count = filteredCategories.length;

      if (count > 0) {
        evaluationData.push(count);
        backgroundColors.push(evaluation.backgroundColor);
      }
    });

    this.pieChartData = {
      datasets: [
        {
          data: evaluationData,
          backgroundColor: backgroundColors,
        },
      ],
    };

    this.chartOptions = {
      interaction: { mode: null },
      responsive: true,
      plugins: {
        datalabels: {
          color: '#fff',
          font: {
            size: 30,
            family: 'Montserrat',
            weight: 'bold',
          },
        },
      },
    };
  }
}
