import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CategoryResult } from '@isophi/teachers-shared';
import { ChartDataset, ChartOptions, ChartType, Plugin, PluginOptionsByType } from 'chart.js';
import { CategoryResult as pdfCategoryResult, TestData } from 'isophi-pdfs-api';

@Component({
  selector: 'isophi-categories-radar-chart',
  templateUrl: './categories-radar-chart.component.html',
  styleUrls: ['./categories-radar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesRadarChartComponent implements OnChanges {
  @Input() public categoryResults!: (CategoryResult | pdfCategoryResult)[];

  @Input() public testData?: TestData[];

  @Input() public materialServer!: string;

  @Input() public typeOfReport?: string;

  @Input() puppeteerApp = false;

  public radarChartLabels!: string[];

  public radarChartData!: ChartDataset<'radar'>[];

  public radarChartType!: ChartType;

  public chartOptions!: ChartOptions<'radar'>;

  public plugins: Plugin<'radar'>[] = [];

  public imageArr: any[] = [];

  public ngOnChanges(): void {
    if (this.categoryResults) {
      this.createRadarChart();
    }
  }

  public createRadarChart(): void {
    const chartColors = {
      borderColor: 'rgba(27, 42, 250, 1)',
      backgroundColor: 'rgba(27, 42, 250, 0.5)',
      pointBackgroundColor: 'rgba(45,131,247,1)',
      pointBorderColor: '#fff',
      borderWidth: 1,
    };

    this.radarChartLabels = [];
    const chartData = [];
    this.radarChartType = 'radar';
    for (const categoryResult of this.categoryResults) {
      const image = new Image();
      image.src = this.materialServer + categoryResult.category.inactiveIconUrl;
      this.imageArr.push(image);

      this.radarChartLabels.push(categoryResult.category.name);
      chartData.push(categoryResult.percent);
    }
    this.radarChartData = [{ ...chartColors, data: chartData }];

    if (this.typeOfReport === 'basic' || this.typeOfReport === 'comparison') {
      if (this.typeOfReport === 'comparison') {
        this.radarChartData[0].backgroundColor = 'rgba(46, 49, 56, 0.3)';
        this.radarChartData[0].borderColor = 'rgba(46, 49, 56, 1)';
        this.radarChartData[0].pointBackgroundColor = 'rgba(46, 49, 56, 1)';

        this.radarChartData.push({
          backgroundColor: 'rgba(27, 42, 250, 0.5)',
          borderWidth: 1,
          data: this.testData[1].categoryResults.map((val) => val.percent),
        });
      }
      this.chartOptions = {
        interaction: { mode: null },
        responsive: this.puppeteerApp ? false : true,
        scales: {
          r: {
            pointLabels: {
              callback: (pointLabel) => {
                return `     ${pointLabel}     `;
              },
              font: {
                size: 14,
              },
            },
            suggestedMin: 0,
            suggestedMax: 100,
            beginAtZero: true,
            startAngle: (this.categoryResults.length > 7 && -15) || undefined,
            ticks: {
              stepSize: 20,
              callback: (value) => value + '%',
            },
            grid: {
              color: 'rgba(0,0,0, 0.4)',
            },
            angleLines: {
              color: 'rgba(0,0,0, 0.4)',
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: () => {
              return '';
            },
          },
          'centered_percent': false,
          'line_label': false,
          'centered_compared': false,
          'line_compared': false,
          'centered_circle': false,
          'number_label': false,
          'polar_outer_categories': false
        } as unknown as PluginOptionsByType<'radar'>
      };

      this.plugins = [
        {
          id: 'radar_outer_categories',
          afterDraw: (chart: any) => {
            const {
              ctx,
              scales: { r },
            } = chart;
            let offsetX = -70;
            const offsetY = -10;

            r._pointLabelItems.forEach((data, i: number) => {
              if (data.textAlign === 'left' || data.textAlign === 'right') {
                offsetX = -15;
              }

              if (this.categoryResults.length === 6 && i === 3) {
                offsetX = -85;
              }

              ctx.drawImage(this.imageArr[i], data.x + offsetX, data.y + offsetY, 30, 30);
            });
          },
        },
      ];
    } else {
      this.chartOptions = {
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 5,
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              stepSize: 25,
              callback: (value) => value + '%',
            },
            grid: {
              circular: true,
              lineWidth: 1,
              // @ts-expect-error - borderDash is not defined in the type
              borderDash: [5, 15],
              color: ['rgb(215,63,65)', 'rgb(249,209,15)', 'rgb(246,144,97)', 'rgb(117,204,125)'],
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => tooltipItem.formattedValue + ' %',
            },
          },
          'centered_percent': false,
          'line_label': false,
          'centered_compared': false,
          'line_compared': false,
          'centered_circle': false,
          'number_label': false,
          'polar_outer_categories': false,
          'radar_outer_categories': false,
          datalabels: false
        } as unknown as PluginOptionsByType<'radar'>
      };

      this.plugins = [];
    }
  }
}
