import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';

import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class DoLogoutGuard implements CanLoad {
  constructor(private authService: AuthService, private accountService: AccountService) {}

  canLoad(): boolean {
    if (this.authService.loggedUser) {
      this.accountService.logoutRegister();
    }
    return true;
  }
}
