import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CategoryDoughnutChartComponent } from './components/category-doughnut-chart/category-doughnut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { CategoriesRadarChartComponent } from './components/categories-radar-chart/categories-radar-chart.component';
import { CategoriesPolarAreaChartComponent } from './components/categories-polar-area-chart/categories-polar-area-chart.component';
import { SunGraphChartComponent } from './components/sun-graph-chart/sun-graph-chart.component';
import { BasicPieChartComponent } from './components/basic-pie-chart/basic-pie-chart.component';

const COMPONENTS = [
  CategoryDoughnutChartComponent,
  CategoriesRadarChartComponent,
  CategoriesPolarAreaChartComponent,
  SunGraphChartComponent,
  BasicPieChartComponent
];

@NgModule({
  imports: [CommonModule, NgChartsModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class CoreChartsModule {}
