/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `primary` - Základní vzdělání * `secodary` - Středoškolské s maturitou * `secodary_without_diploma` - Středoškolské bez maturity * `higher_professional` - Vyšší odborné vzdělání * `university` - Vysokoškolské vzdělání
 */
export type EducationEnum = 'primary' | 'secodary' | 'secodary_without_diploma' | 'higher_professional' | 'university';

export const EducationEnum = {
    Primary: 'primary' as EducationEnum,
    Secodary: 'secodary' as EducationEnum,
    SecodaryWithoutDiploma: 'secodary_without_diploma' as EducationEnum,
    HigherProfessional: 'higher_professional' as EducationEnum,
    University: 'university' as EducationEnum
};