import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreComponent {
  @Input() public showMore: number | boolean;

  @Input() public defaultNumber: number;

  @Input() public data: any;

  @Input() public text?= false;

  @Input() public backgroundColor?: string;

  @Input() public borderColor?: string;

  @Input() public hasPremium?: boolean;

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  public controlCard(tips: any) {
    if (this.showMore < this.defaultNumber) {
      this.showMore = tips.length;
    } else {
      this.showMore = this.defaultNumber - 1;
    }
  }

  public showText() {
    this.showMore = !this.showMore;
  }
}
