import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartOptions, ChartType, Plugin } from 'chart.js';
import { Category, EvaluationRange } from '@isophi/parents-api';
import { CategoryResult, Child, Legend } from 'isophi-pdfs-api';

@Component({
  selector: 'isophi-sun-graph-chart',
  templateUrl: './sun-graph-chart.component.html',
  styleUrls: ['./sun-graph-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SunGraphChartComponent implements OnChanges {
  @Input() categoryResults!: (
    | {
        category: Category;
        evaluation: {
          title: string;
          code: number;
          textColor: string;
          backgroundColor: string;
        };
        maxScore: string;
        score: string;
        percent: string;
      }
    | CategoryResult
  )[];

  @Input() evaluations!: (EvaluationRange | Legend)[];

  @Input() child!: Child;

  @Input() qReport = false;

  @Input() materialServer!: string;

  @Input() lock?: boolean;

  @Input() puppeteerApp = false;

  @Input() zReport = false;

  public doughnutChartType: ChartType = 'doughnut';

  public doughnutChartData: any;

  public chartOptions!: ChartOptions<'doughnut'>;

  public plugins: Plugin<'doughnut'>[] = [];

  public imageArr: HTMLImageElement[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryResults'] && changes['evaluations'] && this.categoryResults) {
      if (this.lock) {
        this.evaluations = [...this.evaluations];
        this.evaluations.push({
          code: -1,
          backgroundColor: '#a3a3a3',
          id: 50,
          maxPercent: 0,
          maxPoint: 0,
          minPercent: 0,
          minPoint: 0,
          textColor: '#000000',
          title: 'none',
          imageUrl: 'none',
        });
      }
      this.createDoughnutChart();
    }
  }

  public createDoughnutChart(): void {
    this.doughnutChartType = 'doughnut';

    let newCategories: (
      | {
          value: number;
          category: Category;
          evaluation: { title: string; code: number; textColor: string; backgroundColor: string };
          maxScore: string;
          score: string;
          percent: string;
        }
      | (CategoryResult & { value: number })
    )[] = [];
    const evaluationCount = {};
    let newEvaluations = [];

    newCategories = this.categoryResults.map((val) => {
      evaluationCount[val.evaluation.code] = ++evaluationCount[val.evaluation.code] || 1;
      return { ...val, value: 1 };
    });

    newCategories.sort((a, b) => b.evaluation.code - a.evaluation.code);

    newCategories.forEach((val) => {
      const image = new Image();
      image.src = this.materialServer + val.category.inactiveIconUrl;
      this.imageArr.push(image);
    });

    newEvaluations = this.evaluations.map((val) => ({ ...val, value: evaluationCount[val.code] }));

    newEvaluations.sort((a, b) => b.code - a.code);

    if (this.qReport) {
      this.doughnutChartData = {
        datasets: [
          {
            data: newCategories.map((category) => category.value),
            backgroundColor: '#fff',
            borderColor: newCategories.map((category) => category.evaluation.backgroundColor),
            borderWidth: 5,
            borderAlign: 'inner',
            cutout: '0',
            radius: '70%',
          },
          {
            data: newCategories.map((category) => category.value),
            backgroundColor: newCategories.map((category) => category.evaluation.backgroundColor),
            borderWidth: 3,
            borderAlign: 'inner',
            radius: '70%',
            cutout: '25%',
          },
          {
            data: newEvaluations.map((val) => val.value),
            backgroundColor: newEvaluations.map((val) => val.backgroundColor),
            borderWidth: 3,
            borderAlign: 'inner',
            cutout: '32%',
          },
        ],
      };
    } else {
      this.doughnutChartData = {
        datasets: [
          {
            data: newCategories.map((category) => category.value),
            backgroundColor: newCategories.map((category) => category.evaluation.backgroundColor),
            borderWidth: 3,
            borderAlign: 'inner',
            cutout: '0',
            radius: '70%',
          },
          {
            data: newEvaluations.map((val) => val.value),
            backgroundColor: newEvaluations.map((val) => val.backgroundColor),
            borderWidth: 3,
            borderAlign: 'inner',
            cutout: '32%',
          },
        ],
      };
    }

    this.chartOptions = {
      maintainAspectRatio: this.puppeteerApp ? false : true,
      interaction: { mode: null },
      responsive: this.puppeteerApp ? false : true,
      layout: {
        padding: {
          left: 20,
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
    };

    this.plugins = [
      {
        id: 'center-image',
        afterDraw: (chart: any) => {
          const { ctx } = chart;

          let imageHeight = 130;
          let imageWidth = 110;

          if (chart.width <= 550) {
            imageHeight = 100;
            imageWidth = 80;
          }

          const originPointX = chart.width / 2 - imageWidth / 2;
          const originPointY = chart.height / 2 - imageHeight / 2;
          const image = new Image();

          if (this.child.gender === 'female') {
            image.src = 'assets/img/iconsDefault/girl.png';
          } else if (this.child.gender === 'male') {
            image.src = 'assets/img/iconsDefault/boy.png';
          }
          ctx.drawImage(image, originPointX + 15, originPointY + 5, imageWidth, imageHeight);
        },
      },
      {
        id: 'circle-icons',
        afterDraw: (chart: any) => {
          const { ctx, _sortedMetasets } = chart;
          if (this.puppeteerApp) {
            ctx.font = '13px sans-serif';
          } else {
            ctx.font = '10px sans-serif';
          }
          ctx.textAlign = 'left';

          _sortedMetasets[0].data.forEach((arc, i) => {
            const imageSize = 35;
            const originPointX = arc.x - imageSize / 2;
            const originPointY = arc.y - imageSize / 2;

            if (this.qReport) {
              if (this.categoryResults.length > 7) {
                ctx.drawImage(
                  this.imageArr[i],
                  originPointX + (arc.outerRadius - imageSize + 5) * Math.cos(arc.startAngle + 0.25),
                  originPointY + (arc.outerRadius - imageSize + 5) * Math.sin(arc.startAngle + 0.25),
                  imageSize,
                  imageSize
                );
              } else {
                ctx.drawImage(
                  this.imageArr[i],
                  originPointX + (arc.outerRadius - imageSize + 5) * Math.cos(arc.startAngle + 0.5),
                  originPointY + (arc.outerRadius - imageSize + 5) * Math.sin(arc.startAngle + 0.5),
                  imageSize,
                  imageSize
                );
              }
            } else {
              if (this.categoryResults.length > 7) {
                ctx.drawImage(
                  this.imageArr[i],
                  originPointX + (arc.outerRadius - imageSize - 5) * Math.cos(arc.startAngle + 0.23),
                  originPointY + (arc.outerRadius - imageSize - 5) * Math.sin(arc.startAngle + 0.23),
                  imageSize,
                  imageSize
                );
              } else {
                ctx.drawImage(
                  this.imageArr[i],
                  originPointX + (arc.outerRadius - imageSize - 15) * Math.cos(arc.startAngle + 0.5),
                  originPointY + (arc.outerRadius - imageSize - 15) * Math.sin(arc.startAngle + 0.5),
                  imageSize,
                  imageSize
                );
              }
            }

            const lineheight = 15;
            const lines = newCategories[i].category.name.split(' ');
            let offset = 28;

            if (this.categoryResults.length <= 7) {
              offset = 47;
            }

            if (i > 6) {
              ctx.textAlign = 'center';
            }

            if (this.categoryResults.length > 7 && (i <= 3 || i >= 10)) {
              if (i < 13 && i > 9) {
                offset = 30;
              } else {
                offset = 18;
              }
            } else if (this.categoryResults.length <= 7 && (i <= 2 || i >= 6)) {
              offset = 25;

              if (this.categoryResults.length <= 6 && (i < 2 || i > 4)) {
                offset = 20;
              }
            }

            if (this.categoryResults.length > 7) {
              for (let j = 0; j < lines.length; j++) {
                ctx.fillText(
                  lines[j],
                  originPointX + (arc.outerRadius + offset) * Math.cos(arc.startAngle + 0.25),
                  originPointY + (arc.outerRadius + offset) * Math.sin(arc.startAngle + 0.25) + j * lineheight
                );
              }
            } else {
              for (let j = 0; j < lines.length; j++) {
                ctx.fillText(
                  lines[j],
                  originPointX + (arc.outerRadius + offset) * Math.cos(arc.startAngle + 0.4),
                  originPointY + (arc.outerRadius + offset) * Math.sin(arc.startAngle + 0.4) + j * lineheight
                );
              }
            }
          });
        },
      },
    ];
  }
}
