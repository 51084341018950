/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `parents-dashboard` - parents-dashboard * `questionnaire` - questionnaire * `none` - none
 */
export type ContractAppEnum = 'parents-dashboard' | 'questionnaire' | 'none';

export const ContractAppEnum = {
    ParentsDashboard: 'parents-dashboard' as ContractAppEnum,
    Questionnaire: 'questionnaire' as ContractAppEnum,
    None: 'none' as ContractAppEnum
};