/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `permanent` - Trvalá adresa * `temporary` - Přechodná adresa * `contact` - Kontaktní adresa
 */
export type AddressTypeEnum = 'permanent' | 'temporary' | 'contact';

export const AddressTypeEnum = {
    Permanent: 'permanent' as AddressTypeEnum,
    Temporary: 'temporary' as AddressTypeEnum,
    Contact: 'contact' as AddressTypeEnum
};