/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `director` - Ředitel/ka * `deputy` - Zástupce/zástupkyně * `head_teacher` - Vedoucí učitel/ka * `teacher` - Učitel/ka * `assistant` - Asistent/ka * `student` - Student/ka
 */
export type OccupationEnum = 'director' | 'deputy' | 'head_teacher' | 'teacher' | 'assistant' | 'student';

export const OccupationEnum = {
    Director: 'director' as OccupationEnum,
    Deputy: 'deputy' as OccupationEnum,
    HeadTeacher: 'head_teacher' as OccupationEnum,
    Teacher: 'teacher' as OccupationEnum,
    Assistant: 'assistant' as OccupationEnum,
    Student: 'student' as OccupationEnum
};