<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title fw-bold">{{ t('child/addChild') }}</h2>
    <button (click)="activeModal.dismiss(null)" type="button" class="btn-close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ul class="nav nav-tabs" id="add-child-tab" role="tablist">
      <li class="nav-item">
        <a
          (click)="selectActionType(ActionType.MANUAL, $event)"
          class="nav-link"
          [class.active]="actionType === ActionType.MANUAL"
          id="manual-tab"
          href="#"
          role="tab"
        >
          {{ t('child/manual') }}
        </a>
      </li>
      <li class="nav-item">
        <a
          (click)="selectActionType(ActionType.BY_CODE, $event)"
          class="nav-link"
          [class.active]="actionType === ActionType.BY_CODE"
          id="by-code-tab"
          href="#"
          role="tab"
        >
          {{ t('child/iTransfer') }}
        </a>
      </li>
    </ul>

    <div class="tab-content py-3">
      <div class="form-errors">
        <div class="alert alert-danger" role="alert" *ngFor="let error of errors">
          {{ error }}
        </div>
      </div>
      <div class="tab-pane fade show active" [attr.hidden]="actionType === ActionType.MANUAL ? null : true">
        <form #manualFormElement="ngForm">
          <div class="form-group">
            <label for="name">{{ t('child/firstName') }}</label>
            <input
              id="name"
              class="form-control"
              placeholder="{{ t('child/firstName') }}"
              [(ngModel)]="editedChild.firstName"
              name="name"
              required
            />
          </div>
          <hr />
          <div class="form-group">
            <label for="dateOfBirth">{{ t('child/dateOfBirth', { yearExample: birthDateExample }) }}</label>
            <div
              class="input-group date-input"
              [ngStyle]="{ border: date.invalid && (date.dirty || date.touched) ? '2px solid #D8000C' : 'none' }"
            >
              <input
                id="dateOfBirth"
                class="form-control"
                placeholder="dd.mm.rrrr"
                name="dp"
                ngbDatepicker
                #dp="ngbDatepicker"
                #date="ngModel"
                [(ngModel)]="editedChild.birthDate"
                required
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dp.toggle()" type="button">
                  <i class="fe fe-calendar"></i>
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-group">
            <label for="gender">{{ t('child/gender') }}</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" value="male" name="gender" id="genderMale" [(ngModel)]="editedChild.gender" />
              <label class="form-check-label" for="genderMale">{{ t('child/boy') }}</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="female"
                name="gender"
                id="genderFemale"
                [(ngModel)]="editedChild.gender"
              />
              <label class="form-check-label" for="genderFemale">{{ t('child/girl') }}</label>
            </div>
          </div>
          <hr />
          <div class="form-group form-check" *ngIf="childManualRegistrationContract">
            <input
              [(ngModel)]="childManualRegistrationConsent"
              type="checkbox"
              class="form-check-input"
              id="childManualRegistrationContract"
              name="childManualRegistrationContract"
              required
            />
            <label
              class="form-check-label"
              for="childManualRegistrationContract"
              [innerHTML]="childManualRegistrationContract.contractFullText"
            >
            </label>
          </div>
          <div class="form-group">
            <p [innerHtml]="t('child/terms')"></p>
          </div>
        </form>
      </div>
      <div class="tab-pane fade show active" [attr.hidden]="actionType === ActionType.BY_CODE ? null : true">
        <form #byCodeFormElement="ngForm">
          <div class="form-group" *ngIf="kindergarten !== null">
            <div class="alert alert-success d-flex align-items-center" role="alert">
              <i class="fe fe-check-circle me-2" aria-hidden="true"></i>
              <p class="mb-0">{{ t('child/codeIsValid') }}</p>
            </div>
          </div>
          <div class="form-group" *ngIf="kindergarten === null">
            <div class="alert alert-info" role="alert">
              <p class="mb-0">{{ t('child/sharingToBeImplemented') }}</p>
            </div>
          </div>
          <div
            class="form-group kindergarten-code-contract"
            *ngIf="kindergarten !== null && userKindergartenSharingContract"
            [innerHTML]="printableFullText(userKindergartenSharingContract)"
          ></div>
          <hr />
          <div class="form-group">
            <label for="name">{{ t('child/transferId') }}</label>
            <div class="input-group">
              <input
                [(ngModel)]="childCode"
                (ngModelChange)="childCodeChanged()"
                id="code"
                class="form-control"
                placeholder="..."
                name="code"
                required
                cdkFocusRegionstart
              />
            </div>
          </div>
          <div class="form-group form-check" *ngIf="kindergarten !== null && userKindergartenSharingContract">
            <input
              [(ngModel)]="childCodeRegistrationConsent"
              type="checkbox"
              class="form-check-input"
              id="childCodeRegistrationContract"
              name="childCodeRegistrationContract"
              required
            />
            <label
              class="form-check-label"
              for="childCodeRegistrationContract"
              [innerHTML]="childCodeRegistrationContract.contractFullText"
            >
            </label>
          </div>
          <div class="form-group" *ngIf="kindergarten !== null && userKindergartenSharingContract">
            <p [innerHtml]="t('child/terms')"></p>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row">
      <div class="modal-footer-right">
        <button (click)="processActiveForm()" [disabled]="!activeForm || !activeForm.valid" type="submit" class="btn btn-primary w-100">
          <ng-container *ngIf="actionType === ActionType.BY_CODE && kindergarten === null">{{ t('child/validate') }}</ng-container>
          <ng-container *ngIf="actionType !== ActionType.BY_CODE || kindergarten !== null">{{ t('child/add') }}</ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-container>
