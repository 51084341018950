import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-gdpr-info',
  templateUrl: './gdpr-info.component.html',
  styleUrls: ['./gdpr-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdprInfoComponent {
  public gdprContractUrl = `https://isophi.cz/wp-content/uploads/2024/08/iSophiR_Podmínky-zpracovani-OS_v4.pdf`;
}
