/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActivateParent } from '../model/activateParent';
import { FriendlyErrorMessage } from '../model/friendlyErrorMessage';
import { PaginatedParentList } from '../model/paginatedParentList';
import { Parent } from '../model/parent';
import { RegisterParent } from '../model/registerParent';
import { RegistrationResponse } from '../model/registrationResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ParentsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * activateParent
     * Activate new user account and parent role.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateParent(body: ActivateParent, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activateParent(body: ActivateParent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activateParent(body: ActivateParent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activateParent(body: ActivateParent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling activateParent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/parents/activate/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getParent
     * Return selected Parent.    **API available only for internal_app tokens.**
     * @param id A unique integer value identifying this parent.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParent(id: number, observe?: 'body', reportProgress?: boolean): Observable<Parent>;
    public getParent(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Parent>>;
    public getParent(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Parent>>;
    public getParent(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getParent.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Parent>('get',`${this.basePath}/parents/${encodeURIComponent(String(id))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getParents
     * Return list of parents.  If filtered by user_id and parent not found and &#x27;auto_create&#x27; query params set to &#x27;true&#x27;, it will create Parent automatically.  **API available only for internal_app tokens.**
     * @param autoCreate Boolean if auto-create parent, when not found. For more details see endpoint description above.
     * @param isActive Filter by is_active
     * @param limit Number of results to return per page.
     * @param offset The initial index from which to return the results.
     * @param userId Filter by user ID
     * @param userIdIn Filter by user IDs separated by comma.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParents(autoCreate?: string, isActive?: string, limit?: number, offset?: number, userId?: string, userIdIn?: string, observe?: 'body', reportProgress?: boolean): Observable<PaginatedParentList>;
    public getParents(autoCreate?: string, isActive?: string, limit?: number, offset?: number, userId?: string, userIdIn?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedParentList>>;
    public getParents(autoCreate?: string, isActive?: string, limit?: number, offset?: number, userId?: string, userIdIn?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedParentList>>;
    public getParents(autoCreate?: string, isActive?: string, limit?: number, offset?: number, userId?: string, userIdIn?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (autoCreate !== undefined && autoCreate !== null) {
            queryParameters = queryParameters.set('auto_create', <any>autoCreate);
        }
        if (isActive !== undefined && isActive !== null) {
            queryParameters = queryParameters.set('is_active', <any>isActive);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('user_id', <any>userId);
        }
        if (userIdIn !== undefined && userIdIn !== null) {
            queryParameters = queryParameters.set('user_id__in', <any>userIdIn);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedParentList>('get',`${this.basePath}/parents/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * registerNewParent
     * Register new Parent  Creation of user and using of activation code are 2 separated parts, when each can have success/fail result. This API returns 207 Multi-Status response, where is result for each part of registration. FE client should check each part of response and accordingly inform a user. (For example: Registration was successful, but we cannot use your activation code)  By default, parent is not active until he confirms his account by activation email.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerNewParent(body: RegisterParent, observe?: 'body', reportProgress?: boolean): Observable<RegistrationResponse>;
    public registerNewParent(body: RegisterParent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistrationResponse>>;
    public registerNewParent(body: RegisterParent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistrationResponse>>;
    public registerNewParent(body: RegisterParent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerNewParent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RegistrationResponse>('post',`${this.basePath}/parents/register/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
