<ng-container *transloco="let t">
  <div id="main-container">
    <router-outlet></router-outlet>
  </div>

  <isophi-toasts aria-live="polite" aria-atomic="true"></isophi-toasts>

  <ngx-spinner name="primary" bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#fff" type="ball-pulse">
    {{ t('shared/loading') }}
  </ngx-spinner>
</ng-container>
