import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/services/auth-guard.service';
import { environment } from '../environments/environment';

import { ActivationGuard } from './core/guards/activation-guard.guard';
import { DoLogoutGuard } from './core/guards/do-logout.guard';
import { LayoutComponent } from './shared/pages/layout/layout.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ActivationGuard],
    pathMatch: 'full',
    children: [],
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'registration',
    component: LayoutComponent,
    loadChildren: () => import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
    canLoad: [DoLogoutGuard],
  },
  {
    path: 'questionnaire/:childUuid',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/questionnaire/questionnaire.module').then((m) => m.QuestionnaireModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'user',
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'child',
        loadChildren: () => import('./modules/child/child.module').then((m) => m.ChildModule),
      },
      {
        path: 'result/:childUuid',
        loadChildren: () => import('./modules/result/result.module').then((m) => m.ResultModule),
      },
      {
        path: 'communications/:childUuid',
        loadChildren: () => import('./modules/communications/communications.module').then((m) => m.CommunicationsModule),
      },
      {
        path: 'games',
        children: [
          {
            path: '',
            redirectTo: 'e-games',
            pathMatch: 'full',
          },
          {
            path: 'e-games',
            loadChildren: () => import('./modules/e-games/games.module').then((m) => m.GamesModule),
          },
          {
            path: 'word-games',
            loadChildren: () => import('./modules/word-games/word-games.module').then((m) => m.WordGamesModule),
          },
        ],
      },
      {
        path: 'info',
        loadChildren: () => import('./modules/info/info.module').then((m) => m.InfoModule),
      },
    ],
  },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production && false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 75],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
