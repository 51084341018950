import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopUpType } from '@isophi/core-legacy';
import { AlertType } from '@isophi/core-legacy';
import { Observable } from 'rxjs';

import { PopUpDialogComponent } from './components/pop-up-dialog/pop-up-dialog.component';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PopUpComponent {
  public constructor(private modal: NgbModal) {}

  public openDialog(
    title: string,
    text: string,
    popUpType: string,
    alertType: AlertType | null = null,
    data: Record<string, any> | null = null
  ): Observable<string | boolean> {
    if (data === null) {
      data = {};
    }
    const defaultData = {
      alertType,
      title,
      text,
      popUpType,
    };
    data = { ...defaultData, ...data };

    const modalRef = this.modal.open(PopUpDialogComponent);
    modalRef.componentInstance.data = data;
    return modalRef.closed;
  }

  public confirm(
    title: string,
    text: string,
    alertType: AlertType | null = null,
    yesText: string = 'Ano',
    noText: string = 'Ne'
  ): Observable<string | boolean> {
    return this.openDialog(title, text, PopUpType.CONFIRM, alertType, { yesText, noText });
  }

  public alert(title: string, text: string, alertType: AlertType | null = null, okText: string = 'Ok'): Observable<string | boolean> {
    return this.openDialog(title, text, PopUpType.ALERT, alertType, { okText });
  }

  public input(
    title: string,
    label: string,
    placeholder: string,
    defaultValue: string | null = null,
    validationRegex: string | null = null,
    validationMessage: string | null = null,
    okText: string = 'Ok',
    cancelText: string = 'Zrušit'
  ): Observable<string | boolean> {
    return this.openDialog(title, label, 'prompt', null, {
      okText,
      cancelText,
      validationRegex,
      placeholder,
      validationMessage,
      defaultValue,
    });
  }
}
