/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `af` - Afrikaans * `ar` - Arabic * `ar-dz` - Algerian Arabic * `ast` - Asturian * `az` - Azerbaijani * `bg` - Bulgarian * `be` - Belarusian * `bn` - Bengali * `br` - Breton * `bs` - Bosnian * `ca` - Catalan * `ckb` - Central Kurdish (Sorani) * `cs` - Czech * `cy` - Welsh * `da` - Danish * `de` - German * `dsb` - Lower Sorbian * `el` - Greek * `en` - English * `en-au` - Australian English * `en-gb` - British English * `eo` - Esperanto * `es` - Spanish * `es-ar` - Argentinian Spanish * `es-co` - Colombian Spanish * `es-mx` - Mexican Spanish * `es-ni` - Nicaraguan Spanish * `es-ve` - Venezuelan Spanish * `et` - Estonian * `eu` - Basque * `fa` - Persian * `fi` - Finnish * `fr` - French * `fy` - Frisian * `ga` - Irish * `gd` - Scottish Gaelic * `gl` - Galician * `he` - Hebrew * `hi` - Hindi * `hr` - Croatian * `hsb` - Upper Sorbian * `hu` - Hungarian * `hy` - Armenian * `ia` - Interlingua * `id` - Indonesian * `ig` - Igbo * `io` - Ido * `is` - Icelandic * `it` - Italian * `ja` - Japanese * `ka` - Georgian * `kab` - Kabyle * `kk` - Kazakh * `km` - Khmer * `kn` - Kannada * `ko` - Korean * `ky` - Kyrgyz * `lb` - Luxembourgish * `lt` - Lithuanian * `lv` - Latvian * `mk` - Macedonian * `ml` - Malayalam * `mn` - Mongolian * `mr` - Marathi * `ms` - Malay * `my` - Burmese * `nb` - Norwegian Bokmål * `ne` - Nepali * `nl` - Dutch * `nn` - Norwegian Nynorsk * `os` - Ossetic * `pa` - Punjabi * `pl` - Polish * `pt` - Portuguese * `pt-br` - Brazilian Portuguese * `ro` - Romanian * `ru` - Russian * `sk` - Slovak * `sl` - Slovenian * `sq` - Albanian * `sr` - Serbian * `sr-latn` - Serbian Latin * `sv` - Swedish * `sw` - Swahili * `ta` - Tamil * `te` - Telugu * `tg` - Tajik * `th` - Thai * `tk` - Turkmen * `tr` - Turkish * `tt` - Tatar * `udm` - Udmurt * `uk` - Ukrainian * `ur` - Urdu * `uz` - Uzbek * `vi` - Vietnamese * `zh-hans` - Simplified Chinese * `zh-hant` - Traditional Chinese
 */
export type PreferredLanguageEnum = 'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'bg' | 'be' | 'bn' | 'br' | 'bs' | 'ca' | 'ckb' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant';

export const PreferredLanguageEnum = {
    Af: 'af' as PreferredLanguageEnum,
    Ar: 'ar' as PreferredLanguageEnum,
    ArDz: 'ar-dz' as PreferredLanguageEnum,
    Ast: 'ast' as PreferredLanguageEnum,
    Az: 'az' as PreferredLanguageEnum,
    Bg: 'bg' as PreferredLanguageEnum,
    Be: 'be' as PreferredLanguageEnum,
    Bn: 'bn' as PreferredLanguageEnum,
    Br: 'br' as PreferredLanguageEnum,
    Bs: 'bs' as PreferredLanguageEnum,
    Ca: 'ca' as PreferredLanguageEnum,
    Ckb: 'ckb' as PreferredLanguageEnum,
    Cs: 'cs' as PreferredLanguageEnum,
    Cy: 'cy' as PreferredLanguageEnum,
    Da: 'da' as PreferredLanguageEnum,
    De: 'de' as PreferredLanguageEnum,
    Dsb: 'dsb' as PreferredLanguageEnum,
    El: 'el' as PreferredLanguageEnum,
    En: 'en' as PreferredLanguageEnum,
    EnAu: 'en-au' as PreferredLanguageEnum,
    EnGb: 'en-gb' as PreferredLanguageEnum,
    Eo: 'eo' as PreferredLanguageEnum,
    Es: 'es' as PreferredLanguageEnum,
    EsAr: 'es-ar' as PreferredLanguageEnum,
    EsCo: 'es-co' as PreferredLanguageEnum,
    EsMx: 'es-mx' as PreferredLanguageEnum,
    EsNi: 'es-ni' as PreferredLanguageEnum,
    EsVe: 'es-ve' as PreferredLanguageEnum,
    Et: 'et' as PreferredLanguageEnum,
    Eu: 'eu' as PreferredLanguageEnum,
    Fa: 'fa' as PreferredLanguageEnum,
    Fi: 'fi' as PreferredLanguageEnum,
    Fr: 'fr' as PreferredLanguageEnum,
    Fy: 'fy' as PreferredLanguageEnum,
    Ga: 'ga' as PreferredLanguageEnum,
    Gd: 'gd' as PreferredLanguageEnum,
    Gl: 'gl' as PreferredLanguageEnum,
    He: 'he' as PreferredLanguageEnum,
    Hi: 'hi' as PreferredLanguageEnum,
    Hr: 'hr' as PreferredLanguageEnum,
    Hsb: 'hsb' as PreferredLanguageEnum,
    Hu: 'hu' as PreferredLanguageEnum,
    Hy: 'hy' as PreferredLanguageEnum,
    Ia: 'ia' as PreferredLanguageEnum,
    Id: 'id' as PreferredLanguageEnum,
    Ig: 'ig' as PreferredLanguageEnum,
    Io: 'io' as PreferredLanguageEnum,
    Is: 'is' as PreferredLanguageEnum,
    It: 'it' as PreferredLanguageEnum,
    Ja: 'ja' as PreferredLanguageEnum,
    Ka: 'ka' as PreferredLanguageEnum,
    Kab: 'kab' as PreferredLanguageEnum,
    Kk: 'kk' as PreferredLanguageEnum,
    Km: 'km' as PreferredLanguageEnum,
    Kn: 'kn' as PreferredLanguageEnum,
    Ko: 'ko' as PreferredLanguageEnum,
    Ky: 'ky' as PreferredLanguageEnum,
    Lb: 'lb' as PreferredLanguageEnum,
    Lt: 'lt' as PreferredLanguageEnum,
    Lv: 'lv' as PreferredLanguageEnum,
    Mk: 'mk' as PreferredLanguageEnum,
    Ml: 'ml' as PreferredLanguageEnum,
    Mn: 'mn' as PreferredLanguageEnum,
    Mr: 'mr' as PreferredLanguageEnum,
    Ms: 'ms' as PreferredLanguageEnum,
    My: 'my' as PreferredLanguageEnum,
    Nb: 'nb' as PreferredLanguageEnum,
    Ne: 'ne' as PreferredLanguageEnum,
    Nl: 'nl' as PreferredLanguageEnum,
    Nn: 'nn' as PreferredLanguageEnum,
    Os: 'os' as PreferredLanguageEnum,
    Pa: 'pa' as PreferredLanguageEnum,
    Pl: 'pl' as PreferredLanguageEnum,
    Pt: 'pt' as PreferredLanguageEnum,
    PtBr: 'pt-br' as PreferredLanguageEnum,
    Ro: 'ro' as PreferredLanguageEnum,
    Ru: 'ru' as PreferredLanguageEnum,
    Sk: 'sk' as PreferredLanguageEnum,
    Sl: 'sl' as PreferredLanguageEnum,
    Sq: 'sq' as PreferredLanguageEnum,
    Sr: 'sr' as PreferredLanguageEnum,
    SrLatn: 'sr-latn' as PreferredLanguageEnum,
    Sv: 'sv' as PreferredLanguageEnum,
    Sw: 'sw' as PreferredLanguageEnum,
    Ta: 'ta' as PreferredLanguageEnum,
    Te: 'te' as PreferredLanguageEnum,
    Tg: 'tg' as PreferredLanguageEnum,
    Th: 'th' as PreferredLanguageEnum,
    Tk: 'tk' as PreferredLanguageEnum,
    Tr: 'tr' as PreferredLanguageEnum,
    Tt: 'tt' as PreferredLanguageEnum,
    Udm: 'udm' as PreferredLanguageEnum,
    Uk: 'uk' as PreferredLanguageEnum,
    Ur: 'ur' as PreferredLanguageEnum,
    Uz: 'uz' as PreferredLanguageEnum,
    Vi: 'vi' as PreferredLanguageEnum,
    ZhHans: 'zh-hans' as PreferredLanguageEnum,
    ZhHant: 'zh-hant' as PreferredLanguageEnum
};