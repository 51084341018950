import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, filter, Observable, Subscription } from 'rxjs';
import { LicenseService } from '../../../../app/core/services/license.service';
import { ChildService } from '../../../core/services/child.service';
import { EditLicenseComponent } from '../../../modules/user/components/edit-license/edit-license.component';
import { AuthService } from '@isophi/core-legacy';
import { GoogleTrackingService } from '../../../core/services/google-tracking.service';
import { NavigationEnd, Router } from '@angular/router';
import { ExtendedChild } from '@isophi/parents-api';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  licenses$: Observable<{ premium: boolean }>;

  isConnected: boolean;

  hidePremium$ = new BehaviorSubject<boolean>(true);

  selectedChild$: Observable<ExtendedChild>;

  private _subs: Subscription[] = [];

  constructor(
    private licenseService: LicenseService,
    private modal: NgbModal,
    private childService: ChildService,
    private authService: AuthService,
    private googleTrackingService: GoogleTrackingService,
    private router: Router
  ) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium(),
    });
    this.selectedChild$ = this.childService.getSelectedChild();

    const routesToHidePremium = ['kindergarten', 'communications', 'contact'];

    this._subs.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        const hasRoute = routesToHidePremium.some((val) => event.url.includes(val));

        if (hasRoute) {
          this.hidePremium$.next(false);
        } else {
          this.hidePremium$.next(true);
        }
      })
    );
  }

  ngOnInit(): void {
    if (this.authService.loggedUser) {
      this._subs.push(
        this.childService.isKindergartenConnected().subscribe((val) => this.isConnected = val)
      )
    }
  }

  trackingProductDetail(isConnected: boolean): void {
    this.googleTrackingService.trackViewProductDetail(isConnected);
  }

  ngOnDestroy(): void {
    this._subs.forEach((_subs) => _subs.unsubscribe());
  }

  openActivateCodeDialog(): void {
    this.modal.open(EditLicenseComponent);
  }
}
