/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChildCloseRelativesStatusEnum } from './childCloseRelativesStatusEnum';
import { CloseRelativeAddressUpdate } from './closeRelativeAddressUpdate';
import { RelativeTypeEnum } from './relativeTypeEnum';

export interface ChildCloseRelatives { 
    readonly id: string;
    profile?: number;
    relativeType: RelativeTypeEnum;
    status: ChildCloseRelativesStatusEnum;
    firstName: string;
    lastName: string;
    email?: string;
    phone?: string;
    dataBox?: string;
    bankAccountNumber?: string;
    bankCode?: string;
    bankName?: string;
    iban?: string;
    swift?: string;
    addresses?: Array<CloseRelativeAddressUpdate>;
    birthDate?: string;
}