import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { SystemService } from '@isophi/core-legacy';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root',
})
export class AndroidHelperService {
  constructor(
    private systemService: SystemService,
    private inAppBrowser: InAppBrowser,
    private fileOpener: FileOpener,
    private file: File
  ) {}


  downloadMaterialInAndroid(event: Event, url) {
    if (this.systemService.isCordova()) {
      event.preventDefault();
      event.stopPropagation();

      const options: InAppBrowserOptions = {
        location: 'yes',
        hidden: 'no',
        clearcache: 'yes',
        clearsessioncache: 'yes',
        shouldPauseOnSuspend: 'no',
        closebuttoncaption: 'Close',
        presentationstyle: 'pagesheet',
      };

      const browser = this.inAppBrowser.create(url, '_system', options);
      browser.show();
    }
  }

  downloadPDFInAndroid(response: Blob, fileName: string) {
    const fileDir = this.file.externalDataDirectory; // or any other valid file directory
    const filePath = fileDir + fileName;

    this.file.writeFile(fileDir, fileName, response, { replace: true })
      .then(() => {
        this.fileOpener.open(filePath, response.type)
          .then(() => {
            console.log('File opened successfully');
          })
          .catch(error => {
            console.error('Error opening file:', error);
          });
      })
      .catch(error => {
        console.error('Error saving file:', error);
      });
  }
}
