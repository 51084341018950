/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { DayOffTypeEnum } from './dayOffTypeEnum';
import { NullEnum } from './nullEnum';

export interface KindergartenDayOffRead { 
    readonly id: string;
    name?: string;
    date: string;
    isDisabled?: boolean;
    dayOffType?: DayOffTypeEnum | BlankEnum | NullEnum;
    dateTo?: string;
}