/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `no_evidence` - no_evidence * `present` - present * `absent` - absent * `left` - left
 */
export type ParentChildAttendanceCreateOrUpdateSerializersStatusEnum = 'no_evidence' | 'present' | 'absent' | 'left';

export const ParentChildAttendanceCreateOrUpdateSerializersStatusEnum = {
    NoEvidence: 'no_evidence' as ParentChildAttendanceCreateOrUpdateSerializersStatusEnum,
    Present: 'present' as ParentChildAttendanceCreateOrUpdateSerializersStatusEnum,
    Absent: 'absent' as ParentChildAttendanceCreateOrUpdateSerializersStatusEnum,
    Left: 'left' as ParentChildAttendanceCreateOrUpdateSerializersStatusEnum
};