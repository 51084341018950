/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `without_nationality` - Osoba bez st.občanství * `czech_republic` - Občan ČR * `foreigner_with_permanent_residence` - Cizinec s trv.pobyt.v ČR * `foreigner_without_permanent_residence` - Cizinec bez trv.pobytu * `unknown_or_not_declared` - Obč. neznámé, neudané * `asylum_seeker` - Azylant, žadatel o azyl * `person_with_temporary_protection` - Osoba s dočasnou ochranou * `person_with_supplementary_protection` - Osoba s doplňkovou ochr.
 */
export type NationalityQualifierEnum = 'without_nationality' | 'czech_republic' | 'foreigner_with_permanent_residence' | 'foreigner_without_permanent_residence' | 'unknown_or_not_declared' | 'asylum_seeker' | 'person_with_temporary_protection' | 'person_with_supplementary_protection';

export const NationalityQualifierEnum = {
    WithoutNationality: 'without_nationality' as NationalityQualifierEnum,
    CzechRepublic: 'czech_republic' as NationalityQualifierEnum,
    ForeignerWithPermanentResidence: 'foreigner_with_permanent_residence' as NationalityQualifierEnum,
    ForeignerWithoutPermanentResidence: 'foreigner_without_permanent_residence' as NationalityQualifierEnum,
    UnknownOrNotDeclared: 'unknown_or_not_declared' as NationalityQualifierEnum,
    AsylumSeeker: 'asylum_seeker' as NationalityQualifierEnum,
    PersonWithTemporaryProtection: 'person_with_temporary_protection' as NationalityQualifierEnum,
    PersonWithSupplementaryProtection: 'person_with_supplementary_protection' as NationalityQualifierEnum
};