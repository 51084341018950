import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileService, SystemService } from '@isophi/core-legacy';
import { ChildResultsService, EvaluationRule, LastResults, PatchedShareAllWithTeacher, PatchedVisibleToTeacher } from '@isophi/parents-api';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CategoryResultGroup } from '../models/category-result-group.model';
import { ResultType } from '../models/result-type.model';
import { CategoryService } from './category.service';
import { ChildService } from './child.service';
import { AndroidHelperService } from '../../shared/services/android-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ResultService {
  public selectedResults$: Observable<LastResults>;

  private selectedResultTypeSubject$ = new BehaviorSubject<ResultType>('questionnaire');

  public constructor(
    private childResultsApiService: ChildResultsService,
    private categoryService: CategoryService,
    private childService: ChildService,
    private http: HttpClient,
    private fileService: FileService,
    private systemService: SystemService,
    private androidHelperService: AndroidHelperService
  ) {
    this.selectedResults$ = this.childService.getSelectedChildResults();
  }

  public setSelectedResultType(resultType: ResultType) {
    this.selectedResultTypeSubject$.next(resultType);
  }

  public getSelectedResults(): Observable<LastResults> {
    return this.selectedResults$;
  }

  public getSelectedResultCategoryGroups(
    dataSetUuid: string,
    childUuid: string
  ): Observable<{ [evaluationCode: string]: CategoryResultGroup } | null> {
    return combineLatest([this.childService.getResult(dataSetUuid, childUuid), this.categoryService.getCategoryMap()]).pipe(
      map(([result, categories]) => {
        if (!(result && categories)) {
          return null;
        }

        return {
          categoryResultGroups: result.categoryResults.reduce(
            (groups, { categoryUuid, evaluationCode, evaluationBackgroundColor }) => ({
              ...groups,
              [evaluationCode]: {
                evaluationCode,
                evaluationBackgroundColor,
                categories: [...(groups[evaluationCode]?.categories || []), categories[categoryUuid]],
              },
            }),
            {}
          ),
          childResult: result,
        };
      }),
      map((val) => {
        if (!(val.childResult && val.categoryResultGroups)) {
          return null;
        }

        const newCategoryResultGroups = { ...val.categoryResultGroups };

        for (const result in newCategoryResultGroups) {
          const filterdCategories = newCategoryResultGroups[result].categories.filter(
            (category) => !val.childResult.lockedCategories.includes(category.uuid)
          );
          newCategoryResultGroups[result].categories = filterdCategories;
        }

        return newCategoryResultGroups;
      })
    );
  }

  public getQuestionnaireCategoriesEvaluationCodes(): Observable<{ [uuid: string]: number } | null> {
    return this.childService.getSelectedChildResults().pipe(
      map((lastResults) =>
        lastResults?.questionnaire
          ? lastResults.questionnaire.categoryResults.reduce(
              (categoryEvaluationCodes, categoryResult) => ({
                ...categoryEvaluationCodes,
                [categoryResult.categoryUuid]: lastResults.questionnaire.lockedCategories.includes(categoryResult.categoryUuid)
                  ? 3
                  : categoryResult.evaluationCode,
              }),
              {}
            )
          : null
      )
    );
  }

  public getGeneralEvaluationRule(uuid: string): Observable<EvaluationRule> {
    return this.childResultsApiService.getGeneralEvaluationRule(uuid);
  }

  public getResultEvaluationRule(): Observable<EvaluationRule | null> {
    return this.selectedResults$.pipe(
      switchMap((result) => {
        if (result?.dadTest || result?.dadTestZ || result?.questionnaire) {
          const dataSetUuid = result?.dadTest
            ? result.dadTest.dataSetUuid
            : result?.dadTestZ
            ? result?.dadTestZ.dataSetUuid
            : result?.questionnaire.dataSetUuid;

          return this.getGeneralEvaluationRule(dataSetUuid);
        } else {
          return of(null);
        }
      }),
      tap((evaluationRule) => evaluationRule?.ranges?.sort((a, b) => b.code - a.code))
    );
  }

  public clear() {
    this.selectedResultTypeSubject$.next('questionnaire');
  }

  public downloadParentQuestionnaireReport(dataSetUuid: string, fileName: string = 'parentQuestionnaireReport') {
    const url = `${environment.pdfApiBase}/api/v1/parent-reports/questionnaire/`;
    return this.downloadReport(url, dataSetUuid, fileName);
  }

  /**
   * Download report (Printer app).
   **/
  public downloadReport(url: string, dataSetUuid: string, fileName: string = 'report') {
    return this.http
      .post(
        url,
        {
          data_set_uuid: dataSetUuid,
        },
        { responseType: 'blob' }
      )
      .pipe(
        map((response) => {
          if (this.systemService.isCordova()) {
            this.androidHelperService.downloadPDFInAndroid(response, `${fileName}.pdf`);
          } else {
            this.fileService.downloadFile(response, `${fileName}.pdf`, 'application/json');
          }
          return true;
        }),
        catchError((val) => {
          console.error(val);
          throw new Error('Unable to download report.');
        })
      );
  }

  public shareWithTeacher(dataSetUuid, visibleToTeacher): Observable<void> {
    const data: PatchedVisibleToTeacher = { visibleToTeacher };
    return this.childResultsApiService.shareWithTeacher(dataSetUuid, data);
  }

  public shareAllWithTeacher(childUuid: string): Observable<void> {
    const data: PatchedShareAllWithTeacher = { childUuid };
    return this.childResultsApiService.shareAllWithTeacher(data);
  }
}
