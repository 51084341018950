<main *transloco="let t" class="container">
  <div class="row min-vh-100 align-items-center">
    <div class="col-6">
      <div class="display-1 text-primary mb-5 fw-bold">404</div>
      <p class="not-found">
        <span class="text-primary">{{ t('shared/oops') }}</span
        ><br />{{ t('shared/notFound') }}
      </p>
      <p>
        {{ t('shared/contactSupport') }}
        <a href="https://rodic@isophi.cz" target="_blank" class="text-decoration-underline fw-bold">{{
          t('shared/contactSupportLinkText')
        }}</a
        >.
      </p>
      <button type="button" class="btn btn-primary d-flex align-items-center fw-bold" (click)="returnBack()">
        <i class="fe fe-arrow-left"></i> {{ t('shared/returnBack') }}
      </button>
    </div>
    <div class="col-6">
      <img class="img-fluid" src="assets/img/iSophiR_404.svg" alt="404" />
    </div>
  </div>
</main>
