/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FriendlyErrorMessage } from './friendlyErrorMessage';

/**
 * Parent of Multi-Status response in registration parent.
 */
export interface MultiResponsePart { 
    /**
     * HTTP status response code for this part. For example: 200, 400, ...
     */
    status?: number;
    /**
     * Status response message. For example: Ok, Client error, ...
     */
    message?: string;
    errors?: FriendlyErrorMessage;
}