import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreChartsModule } from '@isophi/core-charts';
import { CoreLegacyModule } from '@isophi/core-legacy';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PopUpDialogComponent } from './components/pop-up/components/pop-up-dialog/pop-up-dialog.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';

import { CoreUiModule } from '@isophi/core-ui';
import { UiTemplateModule } from '@isophi/ui-template';
import { BuyLicenseComponent } from './components/buy-license/buy-license.component';
import { ChildSelectorComponent } from './components/child-selector/child-selector.component';
import { DownloadLinkComponent } from './components/download-link/download-link.component';
import { DownloadPreviewComponent } from './components/download-preview/download-preview.component';
import { FooterComponent } from './components/footer/footer.component';
import { GdprInfoComponent } from './components/gdpr-info/gdpr-info.component';
import { FacebookLikePluginComponent } from './components/header/facebook-like-plugin/facebook-like-plugin.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PreviewableComponent } from './components/previewable/previewable.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TargetBlankDirective } from './directives/target-blank.directive';
import { LayoutComponent } from './pages/layout/layout.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { NgSelectModule } from '@ng-select/ng-select';

const COMPONENTS = [
  MenuComponent,
  FooterComponent,
  HeaderComponent,
  PopUpComponent,
  GdprInfoComponent,
  DownloadPreviewComponent,
  DownloadLinkComponent,
  BuyLicenseComponent,
  PreviewableComponent,
  SidebarComponent,
  ShowMoreComponent,
  TargetBlankDirective,
  TextInputComponent
];
@NgModule({
  imports: [
    CommonModule,
    CoreLegacyModule,
    CoreUiModule,
    UiTemplateModule,
    NgbModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    TranslocoModule,
    CoreChartsModule,
    NgxMasonryModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  declarations: [
    ...COMPONENTS,
    ChildSelectorComponent,
    PopUpDialogComponent,
    FacebookLikePluginComponent,
    LayoutComponent,
    NavigationComponent,
    NotFoundComponent
  ],
  exports: [...COMPONENTS, CoreUiModule, NgxSpinnerModule, TranslocoModule, CoreChartsModule, NgxMasonryModule, ReactiveFormsModule, NgSelectModule],
  providers: [PopUpComponent],
})
export class SharedModule {
  public static forRoot() {
    return {
      ngModule: SharedModule,
      declarations: [MenuComponent],
      exports: [MenuComponent],
    };
  }
}
