/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Kindergarten data.
 */
export interface Kindergarten { 
    id: number;
    uuid: string;
    name: string;
    /**
     * Unique public code of kindergarten to identify kindergarten in iSophi eco-system. Code consists of numbers and letters and is 4 character long.
     */
    code: string;
    /**
     * IČO. Company identifier in czech law system.
     */
    lawId?: string;
}