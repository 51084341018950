import { Injectable } from '@angular/core';
import { Child } from '@isophi/parents-api';

@Injectable({
  providedIn: 'root',
})
export class RouterLinkFactory {
  /**
   * Navigate application to landing page.
   *
   * Page not required login.
   */
  public landingPage(): string[] {
    return ['/public'];
  }

  /**
   * Navigate application to homepage.
   *
   * Homepage is main page (component), which is rendered after login.
   * It is always safe to redirect to homepage.
   */
  public homepage(child?: Child): string[] {
    return this.dashboard(child);
  }

  /**
   * Navigate application to login.
   */
  public login(): string[] {
    return ['/login'];
  }

  /**
   * Navigate application to result recommendation by child object.
   */
  public recommendation(): string[] {
    return ['/info', 'recommendation'];
  }

  /**
   * Navigate application to result kindergarten
   */
  public kindergartenResult(child?: Child): string[] {
    if (child) {
      return ['/result', child.uuid, 'kindergarten'];
    } else {
      return ['/result', 'kindergarten'];
    }
  }

  /**
   * Navigate application to result.
   */
  public result(child?: Child): string[] {
    if (child) {
      return ['/result', child.uuid];
    } else {
      return ['/result']; // TODO: (js) these routes didn't work even before
    }
  }

  /**
   * Navigate application to announcement list.
   */
  public announcementList(child?: Child): string[] {
    return ['/communications', child?.uuid, 'announcement'];
  }

  /**
   * Navigate application to absence list.
   */
  public absenceList(child?: Child): string[] {
    return ['/communications', child.uuid, 'absence'];
  }

  /**
   * Navigate application to absence list.
   */
  public createAbsence(child?: Child): string[] {
    return ['/communications', child.uuid, 'absence', 'new'];
  }

  /**
   * Navigate application to absence list.
   */
  public editAbsence(child?: Child, id?: string): string[] {
    return ['/communications', child.uuid, 'absence', 'edit', id];
  }

  /**
  * Navigate application to child close relative.
  */
  public childCloseRelative(childUuid?: string): string[] {
    return ['/communications', childUuid, 'child-close-relative'];
  }

  /**
  * Navigate application to create close relative.
  */
  public createCloseRelative(childUuid?: string): string[] {
    return ['/communications', childUuid, 'child-close-relative', 'new'];
  }

  /**
  * Navigate application to child close relative.
  */
  public editCloseRelative(childUuid?: string, id?: string): string[] {
    return ['/communications', childUuid, 'child-close-relative', 'edit', id];
  }

  /**
  * Navigate application to child profile.
  */
  public childProfile(childUuid?: string): string[] {
    return ['/communications', childUuid, 'child-profile'];
  }

  /**
  * Navigate application to child profile.
  */
  public editChildProfile(childUuid?: string): string[] {
    return ['/communications', childUuid, 'child-profile', 'edit'];
  }

  /**
   * Navigate application to forgotten password page, where user can reset his password.
   */
  public forgottenPassword(): string[] {
    return ['/login', 'forgotten-password'];
  }

  /**
   * Navigate application to registration page.
   */
  public registration(): string[] {
    return ['/registration'];
  }

  /**
   * Navigate application to dashboard.
   *
   */
  public dashboard(child?: Child): string[] {
    if (child) {
      return ['/child', 'dashboard', child.uuid];
    } else {
      return ['/child', 'dashboard'];
    }
  }

  /**
   * Navigate application to change password page.
   *
   */
  public changePassword(): string[] {
    return ['/user', 'change-password'];
  }

  /**
   * Navigate application to licenses page.
   *
   */
  public licenses(): string[] {
    return ['/user', 'licenses'];
  }

  /**
   * Navigate application to sync.
   */
  public sync(): string[] {
    return ['/sync'];
  }

  /**
   * Navigate application to show page with successful activation of an account.
   */
  public activatedPage(): string[] {
    return ['/registration', 'activate'];
  }

  /**
   * Navigate application to show page with successful registration of an account.
   */
  public successPage(): string[] {
    return ['/registration', 'success'];
  }

  /**
   * Navigate application to fill questionnaire for a child.
   */
  public fillQuestionnaire(child?: Child): string[] {
    if (child) {
      return ['/questionnaire', child.uuid, 'fill'];
    } else {
      return ['/questionnaire', 'fill'];
    }
  }

  /**
   * Navigate application to edit user data page.
   */
  public editUser(): string[] {
    return ['/user', 'edit-user'];
  }

  public games(): string[] {
    return ['/games', 'e-games'];
  }

  public play(url: string): string[] {
    return ['/games', 'e-games', url];
  }

  public wordGames(): string[] {
    return ['/games', 'word-games'];
  }

  /**
   * Info page - Why to use the tool.
   */
  public whyIsophi(): string[] {
    return ['/info', 'why-isophi'];
  }

  /**
   * Info page - contact info.
   */
  public contact(): string[] {
    return ['/info', 'contact'];
  }
}
