/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `worksheet` - Worksheet * `worksheet_all` - Worksheet All * `all_tips_audio` - All tips audio * `video` - Video * `category_explanation` - Explanation * `tip` - Tip * `consulting_tip` - Consulting tip
 */
export type ActivityTypeEnum = 'worksheet' | 'worksheet_all' | 'all_tips_audio' | 'video' | 'category_explanation' | 'tip' | 'consulting_tip';

export const ActivityTypeEnum = {
    Worksheet: 'worksheet' as ActivityTypeEnum,
    WorksheetAll: 'worksheet_all' as ActivityTypeEnum,
    AllTipsAudio: 'all_tips_audio' as ActivityTypeEnum,
    Video: 'video' as ActivityTypeEnum,
    CategoryExplanation: 'category_explanation' as ActivityTypeEnum,
    Tip: 'tip' as ActivityTypeEnum,
    ConsultingTip: 'consulting_tip' as ActivityTypeEnum
};