/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Gender785Enum } from './gender785Enum';
import { Kindergarten } from './kindergarten';
import { NullEnum } from './nullEnum';

/**
 * Child data
 */
export interface ExtendedChild { 
    uuid: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    gender?: Gender785Enum | NullEnum;
    /**
     * Version of this entity (how many times it was changed)
     */
    readonly revision: number;
    kindergarten?: Kindergarten;
    thumbnail?: string;
    profileImage?: string;
}