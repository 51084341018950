<div *transloco="let t">
  <p [innerHtml]="t('shared/gdprInfo1')"></p>
  <p [innerHtml]="t('shared/gdprInfo2')"></p>
  <p>
    {{ t('shared/gdprInfo31') }}
    <a [href]="gdprContractUrl" target="_blank" class="fw-bold text-primary">{{ t('shared/gdprInfo32') }}</a
    >{{ t('shared/gdprInfo33') }}
  </p>
  <p>{{ t('shared/gdprInfo4') }}</p>
</div>
