/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `mother` - mother * `father` - father * `step_mother` - step_mother * `step_father` - step_father * `grandmother` - grandmother * `grandfather` - grandfather * `sibling` - sibling * `aunt` - aunt * `uncle` - uncle * `foster_parent` - foster_parent * `guardian` - guardian * `family_friend` - family_friend * `relative` - relative * `close_relative` - close_relative * `other` - other
 */
export type RelativeTypeEnum = 'mother' | 'father' | 'step_mother' | 'step_father' | 'grandmother' | 'grandfather' | 'sibling' | 'aunt' | 'uncle' | 'foster_parent' | 'guardian' | 'family_friend' | 'relative' | 'close_relative' | 'other';

export const RelativeTypeEnum = {
    Mother: 'mother' as RelativeTypeEnum,
    Father: 'father' as RelativeTypeEnum,
    StepMother: 'step_mother' as RelativeTypeEnum,
    StepFather: 'step_father' as RelativeTypeEnum,
    Grandmother: 'grandmother' as RelativeTypeEnum,
    Grandfather: 'grandfather' as RelativeTypeEnum,
    Sibling: 'sibling' as RelativeTypeEnum,
    Aunt: 'aunt' as RelativeTypeEnum,
    Uncle: 'uncle' as RelativeTypeEnum,
    FosterParent: 'foster_parent' as RelativeTypeEnum,
    Guardian: 'guardian' as RelativeTypeEnum,
    FamilyFriend: 'family_friend' as RelativeTypeEnum,
    Relative: 'relative' as RelativeTypeEnum,
    CloseRelative: 'close_relative' as RelativeTypeEnum,
    Other: 'other' as RelativeTypeEnum
};