/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `doctor` - doctor * `sickness` - sickness * `vacation` - vacation * `family_reason` - family_reason * `other` - other
 */
export type AbsenceReasonEnum = 'doctor' | 'sickness' | 'vacation' | 'family_reason' | 'other';

export const AbsenceReasonEnum = {
    Doctor: 'doctor' as AbsenceReasonEnum,
    Sickness: 'sickness' as AbsenceReasonEnum,
    Vacation: 'vacation' as AbsenceReasonEnum,
    FamilyReason: 'family_reason' as AbsenceReasonEnum,
    Other: 'other' as AbsenceReasonEnum
};