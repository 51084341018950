/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `absent` - absent * `absent_morning` - absent_morning * `absent_afternoon` - absent_afternoon
 */
export type AbsenceEnum = 'absent' | 'absent_morning' | 'absent_afternoon';

export const AbsenceEnum = {
    Absent: 'absent' as AbsenceEnum,
    AbsentMorning: 'absent_morning' as AbsenceEnum,
    AbsentAfternoon: 'absent_afternoon' as AbsenceEnum
};