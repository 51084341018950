/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Result in one psychological area (category).  Usually one category result is part of psychological test/questionnaire/...
 */
export interface CategoryResult { 
    categoryUuid: string;
    /**
     * Color in HEX format, e.g. #E02D2D
     */
    evaluationBackgroundColor: string;
    evaluationCode: number;
    /**
     * Color in HEX format, e.g. #E02D2D
     */
    evaluationTextColor: string;
    evaluationTitle: string;
    /**
     * Maximum score in this category in source
     */
    maxScore: string;
    /**
     * Score that the child earned in this category
     */
    score: string;
    percent: string;
}