import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';

import { RouterLinkFactory } from '../router/router-link.factory';

@Injectable()
export class AuthGuardService implements CanActivate {
  public constructor(private routerLinkFactory: RouterLinkFactory, private router: Router, private authService: AuthService) {}

  public canActivate(): boolean {
    if (!this.authService.loggedUser) {
      this.router.navigate(this.routerLinkFactory.login());
      return false;
    }

    return true;
  }
}
