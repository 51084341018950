/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `vzp` - Všeobecná zdravotní pojišťovna ČR * `zps` - Zaměstnanecká pojišťovna Škoda * `zpmv` - Zdravotní pojišťovna ministerstva vnitra * `cpzp` - Česká průmyslová zdravotní pojišťovna * `ozp` - Oborová zdravotní pojišťovna zaměstnanců bank, pojišťoven a stavebnictví * `rbp` - RBP, zdravotní pojišťovna * `vozp` - Vojenská zdravotní pojišťovna ČR
 */
export type InsuranceCompanyEnum = 'vzp' | 'zps' | 'zpmv' | 'cpzp' | 'ozp' | 'rbp' | 'vozp';

export const InsuranceCompanyEnum = {
    Vzp: 'vzp' as InsuranceCompanyEnum,
    Zps: 'zps' as InsuranceCompanyEnum,
    Zpmv: 'zpmv' as InsuranceCompanyEnum,
    Cpzp: 'cpzp' as InsuranceCompanyEnum,
    Ozp: 'ozp' as InsuranceCompanyEnum,
    Rbp: 'rbp' as InsuranceCompanyEnum,
    Vozp: 'vozp' as InsuranceCompanyEnum
};