/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Psychological category (area)
 */
export interface Category { 
    uuid: string;
    /**
     * Absolute path in material portal to active icon of this category
     */
    activeIconUrl: string;
    /**
     * Absolute path in material portal to inactive icon of this category
     */
    inactiveIconUrl: string;
    /**
     * Color in HEX format, e.g. #E02D2D
     */
    color: string;
    name: string;
}