import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@isophi/core-legacy';
import { ToastService } from '@isophi/core-ui';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { RouterLinkFactory } from '../router/router-link.factory';
import { LicenseService } from '../services/license.service';

@Injectable({
  providedIn: 'root'
})
export class ActivationGuard implements CanActivate {
  public constructor(
    private routerLinkFactory: RouterLinkFactory,
    private router: Router,
    private authService: AuthService,
    private licenseService: LicenseService,
    private toastr: ToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const code = route.queryParams?.code;

    if (code) {
      if (this.authService.loggedUser) {
        return this.licenseService.applyActivationCode(code).pipe(
          map(() => this.router.createUrlTree(this.routerLinkFactory.homepage())),
          tap(() => this.toastr.success('Licence úspěšně aktivována')),
          catchError(() => {
            this.toastr.warning('Nepodařilo se. Aktivační kód neexistuje nebo byl již použit.');
            return of(this.router.createUrlTree(this.routerLinkFactory.homepage()));
          })
        );
      } else {
        return this.licenseService.checkActivationCode().pipe(
          map(() => this.router.createUrlTree(this.routerLinkFactory.login())),
          tap(() => {
            this.licenseService.codeByMail = code;
            this.toastr.success('Pro další krok v aktivaci se přihlaste nebo zaregistrujte.');
          }),
          catchError(() => {
            this.toastr.warning('Nepodařilo se. Aktivační kód neexistuje nebo byl již použit.');
            return of(this.router.createUrlTree(this.routerLinkFactory.login()));
          })
        );
      }
    } else {
      return this.router.createUrlTree(this.routerLinkFactory.homepage());
    }
  }
}
