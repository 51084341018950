import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '@isophi/core-ui';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { LicenseService } from '../../../../core/services/license.service';
import { ChildService } from '../../../../core/services/child.service';
import { Subscription } from 'rxjs';
import { BriefSubjectDetail } from '../../../../core/models/brief-subject-detail.model';
import { TranslocoService } from '@ngneat/transloco';
import { GoogleTrackingService } from '../../../../core/services/google-tracking.service';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditLicenseComponent implements OnInit, OnDestroy {
  activationCode = '';

  isConnected: boolean;

  licenses: BriefSubjectDetail;

  private _subs: Subscription[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public licenseService: LicenseService,
    private toastr: ToastService,
    private childService: ChildService,
    private googleTrackingService: GoogleTrackingService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this.childService.isKindergartenConnected().subscribe((val) => this.isConnected = val)
    )
  }

  onSubmit(): void {
    this.licenseService.applyActivationCode(this.activationCode).subscribe({
      next: () => {
        this.activeModal.close();
        this.toastr.success(this.translocoService.translate('user/activationCodeSuccess'));

        this.licenseService.getBriefSubjectDetail().subscribe((licenses) => {
          this.googleTrackingService.trackPurchase(this.isConnected, licenses, this.activationCode);
        })
      },
      error: () => {
        this.toastr.error(this.translocoService.translate('user/activationCodeError'));
      },
    });
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
