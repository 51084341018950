import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { AuthService, User } from '@isophi/core-legacy';
import { ChildService } from '../../../core/services/child.service';
import { LicenseService } from '../../../core/services/license.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  public loggedUser: User | null = null;

  public constructor(public routerLinkFactory: RouterLinkFactory, private authService: AuthService, private childService: ChildService, private licenseService: LicenseService, private router: Router) {
    this.loggedUser = this.authService.loggedUser;
  }

  url(): void {
    this.childService.getChildren().subscribe((children) => {
      if (children.length === 1) {
        const child = children[0];
        this.licenseService.communicationPremium(child.uuid).subscribe((val) => {
          if (val) {
            this.router.navigate(this.routerLinkFactory.announcementList(child));
          } else {
            this.router.navigate(this.routerLinkFactory.homepage(child));
          }
        })
      } else {
        this.router.navigate(this.routerLinkFactory.homepage());
      }
    });
  }

  sidebarClose() {
    document.querySelector('.app')?.classList.remove('sidenav-toggled');
  }

  isMobile(): boolean {
    return window.innerWidth < 992;
  }
}
