<div class="dropdown" *transloco="let t">
  <button
    class="btn btn-primary dropdown-toggle d-inline-flex align-items-center"
    type="button"
    id="dropdown-child-selector-button"
    data-bs-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [disabled]="children?.length === 1"
  >
    <span class="child-selector-selected-name pr-2">
      {{ selectedChild ? selectedChild.firstName : t('shared/selectChild') }}
    </span>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdown-child-selector-button">
    <a
      *ngFor="let child of children"
      (click)="url(child)"
      routerLinkActive="active"
      class="dropdown-item"
      [class.active]="child === selectedChild"
    >
      {{ child.firstName }}
    </a>
  </div>
</div>
