import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { AccountService } from '../../../core/services/account.service';
import { ChildService } from '../../../core/services/child.service';
import { LicenseService } from '../../../core/services/license.service';
import { AuthService } from '@isophi/core-legacy';
import { ExtendedChild, LastResults } from '@isophi/parents-api';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  licenses$: Observable<{ premium: boolean }>;

  children$: Observable<ExtendedChild[]>;

  selectedChild$: Observable<ExtendedChild>;

  results$: Observable<LastResults>;

  public constructor(
    public routerLinkFactory: RouterLinkFactory,
    private childService: ChildService,
    private licenseService: LicenseService,
    private accountService: AccountService,
    private authService: AuthService
  ) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium(),
    });
    this.children$ = this.childService.getChildren();
    this.selectedChild$ = this.childService.getSelectedChild();
    this.results$ = this.childService.getSelectedChildResults();
  }

  get username(): string {
    return this.authService.loggedUser?.email;
  }

  onLogout() {
    this.accountService.logout();
  }
}
