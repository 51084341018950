import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

import { BuyLicenseComponent } from '../buy-license/buy-license.component';

@Component({
  selector: 'app-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLinkComponent {
  @Input() title = 'Stáhnout PDF';

  @Input() url: string;

  @Input() locked = false;

  @Input() worksheetAll = false;

  public materialServer = environment.materialServer

  constructor(private modal: NgbModal) {}

  public openBuyLicense(event) {
    this.modal.open(BuyLicenseComponent);
    event.preventDefault();
  }
}
