/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { EducationEnum } from './educationEnum';
import { LengthOfPracticeEnum } from './lengthOfPracticeEnum';
import { NullEnum } from './nullEnum';
import { OccupationEnum } from './occupationEnum';
import { RegionEnum } from './regionEnum';

/**
 * Parent
 */
export interface Parent { 
    readonly id: number;
    userId: number;
    /**
     * Boolean if parent role is active
     */
    isActive?: boolean;
    lengthOfPractice?: LengthOfPracticeEnum | BlankEnum | NullEnum;
    region?: RegionEnum | BlankEnum | NullEnum;
    education?: EducationEnum | BlankEnum | NullEnum;
    occupation?: OccupationEnum | BlankEnum | NullEnum;
    nickname?: string;
}