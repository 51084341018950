import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CategoryResult, Evaluation } from '@isophi/teachers-shared';
import { ChartDataset, ChartOptions, ChartType, Plugin, PluginOptionsByType } from 'chart.js';
import { CategoryResult as pdfCategoryResult, Legend } from 'isophi-pdfs-api';

@Component({
  selector: 'isophi-categories-polar-area-chart',
  templateUrl: './categories-polar-area-chart.component.html',
  styleUrls: ['./categories-polar-area-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesPolarAreaChartComponent implements OnChanges {
  @Input() public categories!: (CategoryResult | pdfCategoryResult)[];

  @Input() public materialServer!: string;

  @Input() public evaluations!: (Evaluation | Legend)[];

  @Input() public puppeteerApp = false;

  public sortedCategories!: ((CategoryResult & { length: number }) | (pdfCategoryResult & { length: number }))[];

  public polarAreaChartType!: ChartType;

  public polarAreaChartData!: ChartDataset<'polarArea'>[];

  public chartOptions!: ChartOptions<'polarArea'>;

  public plugins: Plugin<'polarArea'>[] = [];

  public arcFactor = 20;

  public indexOfCenters = {};

  public imageArr: any[] = [];

  ngOnChanges(): void {
    if (this.categories && this.evaluations) {
      this.manuplateInputData(this.evaluations, this.categories);
      this.createPolarAreaChart();
    }
  }

  public manuplateInputData(evaluation: (Evaluation | Legend)[], categories: (CategoryResult | pdfCategoryResult)[]): any {
    this.sortedCategories = [...categories].sort((a, b) => (b.evaluation?.code || 0) - (a.evaluation?.code || 0)).map((val) => ({ ...val, length: 0 }));

    const length = {};
    for (const eva of evaluation) {
      const filteredCategories = categories.filter((val) => val.evaluation?.code === eva.code);
      const categoriesLength = filteredCategories.length;

      if (categoriesLength > 0) {
        length[eva.code] = categoriesLength;
        this.indexOfCenters[categoriesLength] = Math.ceil(categoriesLength / 2);
      }
    }

    this.sortedCategories = this.sortedCategories.map((val) => {
      const image = new Image();
      image.src = this.materialServer + val.category.inactiveIconUrl;
      this.imageArr.push(image);
      return { ...val, length: length[val.evaluation.code] };
    });
  }

  public createPolarAreaChart(): void {
    this.polarAreaChartType = 'polarArea';

    this.polarAreaChartData = [
      {
        data: this.sortedCategories.map((category) => category.length + this.arcFactor),
        backgroundColor: this.sortedCategories.map((val) => val.evaluation?.backgroundColor),
        borderColor: this.sortedCategories.map((val) => val.evaluation?.backgroundColor),
        angle:
          this.sortedCategories.length === 14
            ? 19.2857
            : this.sortedCategories.length === 13
              ? 20.7692
              : this.sortedCategories.length === 7
                ? 38.5714
                : this.sortedCategories.length === 6
                  ? 45
                  : 22.5,
      },
    ];

    this.chartOptions = {
      interaction: { mode: null },
      scales: {
        r: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          pointLabels: {
            display: false,
          },
        },
      },
      responsive: this.puppeteerApp ? false : true,
      plugins: {
        'radar_outer_categories': false,
        'centered_percent': false,
        'line_label': false,
        'centered_compared': false,
        'line_compared': false,
        tooltip: false,
        datalabels: false

      } as unknown as PluginOptionsByType<'polarArea'>
    };

    this.plugins = [
      {
        id: 'centered_circle',
        afterDraw: (chart: any) => {
          const {
            ctx,
            scales: { r },
          } = chart;

          const image = new Image();
          image.src = 'assets/img/hole.png';
          ctx.drawImage(image, r.xCenter - 50, r.yCenter - 50, 100, 100);
        },
      },
      {
        id: 'number_label',
        afterDraw: (chart: any) => {
          const { ctx, _sortedMetasets } = chart;
          let counter = 1;

          _sortedMetasets[0].data.forEach((arc) => {
            const value = arc.$context.raw - this.arcFactor;

            if (this.indexOfCenters[value] === counter) {
              let offsetAngle = 0.18;
              const originPointX = arc.x;
              const originPointY = arc.y;

              ctx.font = 'bold 36px Merriweather, sans-serif';

              if (this.sortedCategories.length <= 7) {
                offsetAngle = 0.7;

                if (value === 1) {
                  offsetAngle = 0.3;
                }
              }

              if (this.sortedCategories.length > 7 && value === 2) {
                offsetAngle = 0.25;
              } else if (this.sortedCategories.length > 7 && value === 1) {
                offsetAngle = 0.13;
              }

              let factor = 2;

              if (value > 2) {
                factor = 3;
              }

              ctx.fillText(
                value,
                originPointX + (arc.outerRadius / factor) * Math.cos(arc.startAngle + offsetAngle),
                originPointY + (arc.outerRadius / factor) * Math.sin(arc.startAngle + offsetAngle)
              );
            }

            if (counter === value) {
              counter = 1;
            } else {
              counter += 1;
            }
          });
        },
      },
      {
        id: 'polar_outer_categories',
        afterDraw: (chart: any) => {
          const { ctx, _sortedMetasets } = chart;

          _sortedMetasets[0].data.forEach((arc, index: number) => {
            const iconSize = 45;
            let offsetAngle = 0.18;
            const originPointX = arc.x - iconSize / 2;
            const originPointY = arc.y - iconSize / 2;

            if (this.sortedCategories.length <= 7) {
              offsetAngle = 0.3;
            }

            ctx.drawImage(
              this.imageArr[index],
              originPointX + (arc.outerRadius - iconSize) * Math.cos(arc.startAngle + offsetAngle),
              originPointY + (arc.outerRadius - iconSize) * Math.sin(arc.startAngle + offsetAngle),
              iconSize,
              iconSize
            );
          });
        },
      },
      // {
      //   afterDraw: (chart: any) => {
      //     const { ctx, _sortedMetasets } = chart;
      //     console.log(_sortedMetasets);
      //     ctx.save();
      //     _sortedMetasets[0].data.forEach((arc, i) => {
      //       const halfArcCircumference = (2 * Math.PI * arc.outerRadius) / 4 / 2;
      //       const iconSize = 30;
      //       ctx.font = '13px bold sans-serif';
      //       ctx.lineWidth = 2;

      //       if (i === 0) {
      //         ctx.beginPath();
      //         ctx.moveTo(arc.x + 2, arc.y - 130);
      //         ctx.lineTo(250, 170);
      //         ctx.lineTo(90, 170);
      //         ctx.fillText(this.evaluations[i].title, 90, 165);
      //         ctx.strokeStyle = this.evaluations[i].backgroundColor;
      //         ctx.stroke();
      //       } else if (i === 1) {
      //         ctx.beginPath();
      //         ctx.moveTo(arc.x + halfArcCircumference - iconSize, arc.y + halfArcCircumference - iconSize);
      //         ctx.lineTo(arc.x + halfArcCircumference, arc.y + halfArcCircumference);
      //         ctx.lineTo(arc.x + halfArcCircumference + 50, arc.y + halfArcCircumference);
      //         ctx.fillText(this.evaluations[i].title, arc.x + halfArcCircumference, arc.y + halfArcCircumference - 5);
      //         ctx.strokeStyle = this.evaluations[i].backgroundColor;
      //         ctx.stroke();
      //       } else {
      //         ctx.beginPath();
      //         ctx.moveTo(arc.x - halfArcCircumference + iconSize, arc.y + halfArcCircumference - iconSize);
      //         ctx.lineTo(arc.x - halfArcCircumference, arc.y + halfArcCircumference);
      //         ctx.lineTo(arc.x - halfArcCircumference - 50, arc.y + halfArcCircumference);
      //         ctx.fillText(this.evaluations[i].title, arc.x - halfArcCircumference - 50, arc.y + halfArcCircumference - 5);
      //         ctx.strokeStyle = this.evaluations[i].backgroundColor;
      //         ctx.stroke();
      //       }
      //     });
      //     ctx.restore();
      //   }
      // }
    ];
  }
}
