import { Injectable } from '@angular/core';
import { Contract, ContractsService } from '@isophi/parents-api';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private contracts$: { [keyword: string]: Observable<Contract> } = {};

  public constructor(private contractApiService: ContractsService) {}

  getContract(keyword: string): Observable<Contract> {
    if (!this.contracts$[keyword]) {
      this.contracts$[keyword] = this.contractApiService.getContract(keyword).pipe(shareReplay(1));
    }
    return this.contracts$[keyword];
  }
}
