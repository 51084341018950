import { Directive, HostBinding, HostListener } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { SystemService } from '@isophi/core-legacy';

@Directive({
  // target every <a target="_blank">...<a>
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[target=_blank]',
})
export class TargetBlankDirective {
  constructor(
    private systemService: SystemService,
    private inAppBrowser: InAppBrowser,
  ) {}
  // will add <a ... rel='noopener noreferrer'>...</a>
  @HostBinding('attr.rel') rel = 'noopener noreferrer';

  @HostListener('click', ['$event']) onClick(event) {
    if (this.systemService.isCordova()) {
      event.preventDefault();
      event.stopPropagation();

      const url = event.target.href;
      const options: InAppBrowserOptions = {
        location: 'yes',
        hidden: 'no',
        clearcache: 'yes',
        clearsessioncache: 'yes',
        shouldPauseOnSuspend: 'no',
        closebuttoncaption: 'Close',
        presentationstyle: 'pagesheet',
      };

      const browser = this.inAppBrowser.create(url, '_system', options);
      browser.show();
    }
  }
}