import { Injectable } from '@angular/core';
import { AuthService, LicenseService as CoreLicenseService } from '@isophi/core-legacy';
import { Observable, map, of, shareReplay, tap } from 'rxjs';
import { BriefSubjectDetail } from '../models/brief-subject-detail.model';


@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  codeByMail = null;

  public constructor(private coreLicenseService: CoreLicenseService, private authService: AuthService) {}

  public reloadLicenses() {
    this.coreLicenseService.reloadLicenses();
  }

  public clear() {
    this.coreLicenseService.clear();
    this.coreLicenseService.licenseSubjectUuid = null;
  }

  public hasPremium(): Observable<boolean> {
    this.checkLicenseSubjectUuid();

    if (this.coreLicenseService.licenseSubjectUuid) {
      return this.coreLicenseService.hasProducts(['questionnaire_5-7_v4', 'parents_app']);
    }
    return of(null);
  }

  public communicationPremium(childUuid: string): Observable<boolean> {
    return this.coreLicenseService
      .callLicensesApi(childUuid)
      .pipe(
        map((data) => {
          if (data.licenses?.['parents_app']?.['communication']) {
            return true;
          }
          return false
        }),
        shareReplay(1)
      );
  }

  public checkLicenseSubjectUuid(): void {
    if (this.authService.loggedUser && this.coreLicenseService.licenseSubjectUuid !== this.authService.loggedUser.uuid) {
      this.coreLicenseService.licenseSubjectUuid = this.authService.loggedUser.uuid;
    }
  }

  // TODO: (js) implement
  public checkActivationCode(): Observable<boolean> {
    return of(true);
  }

  public getBriefSubjectDetail(): Observable<BriefSubjectDetail> {
    return this.coreLicenseService.getBriefSubjectDetail() as Observable<BriefSubjectDetail>;
  }

  public applyActivationCode(code: string): Observable<void> {
    return this.coreLicenseService.applyCode(code).pipe(tap(() => this.reloadLicenses()));
  }
}
