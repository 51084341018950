/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryResult } from './categoryResult';
import { ChildResultSourceEnum } from './childResultSourceEnum';

/**
 * One child result in test/questionnaire/...  Usually child result contains summary over all tested psychological areas. All specific psychological area results are in category_results list.
 */
export interface ChildResult { 
    categoryResults: Array<CategoryResult>;
    /**
     * Psychological categories, which are locked for user with his current license type.
     */
    lockedCategories: Array<string>;
    /**
     * Child's age in time, when result was finished
     */
    childAge: string;
    childUuid: string;
    dataSetUuid: string;
    /**
     * Color in HEX format, e.g. #E02D2D
     */
    evaluationBackgroundColor: string;
    evaluationCode: number;
    /**
     * Color in HEX format, e.g. #E02D2D
     */
    evaluationTextColor: string;
    evaluationTitle: string;
    /**
     * In ISO 8601 format with timezone
     */
    finishedAt: Date;
    /**
     * Maximum score in source
     */
    maxScore: string;
    /**
     * Score that the child earned
     */
    score: string;
    /**
     * Where the result came from, i.e. test, questionnaire, ...  * `dad_test` - dad_test * `questionnaire` - questionnaire
     */
    source: ChildResultSourceEnum;
    /**
     * UUID of specific source, i.e. specific test (e.g. preschool diagnostic), specific questionnaire, ...
     */
    sourceUuid: string;
    visibleToTeacher: boolean;
    visibleToParent: boolean;
}