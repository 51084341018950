import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateAdapter, NgbDateNativeAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChildService } from '../../../core/services/child.service';
import { EditLicenseComponent } from '../../../modules/user/components/edit-license/edit-license.component';
import { Subscription } from 'rxjs';
import { GoogleTrackingService } from '../../../core/services/google-tracking.service';

@Component({
  selector: 'app-buy-license',
  templateUrl: './buy-license.component.html',
  styleUrls: ['./buy-license.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BuyLicenseComponent implements OnInit, OnDestroy {
  isConnected: boolean;

  private _subs: Subscription[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    private childService: ChildService,
    private googleTrackingService: GoogleTrackingService
  ) {}

  ngOnInit(): void {
    this._subs.push(
      this.childService.isKindergartenConnected().subscribe((val) => this.isConnected = val)
    )
  }

  onActivateCode() {
    this.modal.open(EditLicenseComponent);
    this.activeModal.close();
  }

  onGoToEshop(): void {
    this.googleTrackingService.trackViewProductDetail(this.isConnected);

    if (this.isConnected) {
      window.open('https://eshop.isophi.cz/produkt/erko-zvyhodnene', '_blank');
    } else {
      window.open('https://eshop.isophi.cz/produkt/erko', '_blank');
    }
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
