import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuardService } from '../../app/core/services/auth-guard.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [AuthGuardService],
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule should be imported only from AppModule.');
    }
  }
}
