<ng-container *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title fw-bold">{{ t('child/youHaveCode') }}</h2>
    <button (click)="activeModal.dismiss(null)" type="button" class="btn-close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body text-center">
    <button
      (click)="chooseAction(ActionType.BY_CODE)"
      type="button"
      class="btn btn-outline-primary me-sm-6 mx-3 ms-sm-0 px-6 button-border"
    >
      <span class="fw-bold">{{ t('child/yes') }}</span
      ><br />{{ t('child/addChildByCode') }}
    </button>
    <button
      (click)="chooseAction(ActionType.MANUAL)"
      data-cy-test="manual-action"
      type="button"
      class="btn btn-outline-primary px-8 button-border mt-4 mt-sm-0"
    >
      <span class="fw-bold">{{ t('child/no') }}</span
      ><br />{{ t('child/addChildManually') }}
    </button>
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row">
      <div class="modal-footer-right">
        <button (click)="activeModal.dismiss(null)" type="button" class="btn btn-primary w-100">{{ t('child/cancel') }}</button>
      </div>
    </div>
  </div>
</ng-container>
