import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IconService } from '@isophi/core-ui';
import { ExtendedChild, LastResults } from '@isophi/parents-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { BehaviorSubject, Subscription } from 'rxjs';

import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { LicenseService } from '../../../core/services/license.service';
import { hasAnyResult } from '../../../core/utils/result.utils';
import { ChooseActionComponent } from '../../../modules/child/components/choose-action/choose-action.component';
import { PopUpComponent } from '../pop-up/pop-up.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() results: LastResults;

  @Input() selectedChild: ExtendedChild;

  @Input() children: ExtendedChild[];

  communicationPremium$ = new BehaviorSubject<boolean>(false);

  private _subs: Subscription[] = [];

  constructor(
    public routerLinkFactory: RouterLinkFactory,
    private modalService: NgbModal,
    private popUp: PopUpComponent,
    public iconService: IconService,
    private licenseService: LicenseService,
    private elRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadScript();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedChild && changes.selectedChild.currentValue) {
      this._subs.push(
        this.licenseService.communicationPremium(this.selectedChild?.uuid).subscribe((val) => {
          this.communicationPremium$.next(val);
          setTimeout(() => {
            this.menuClick();
            if (val) {
              this.openSlide();
            }
          });
        })
      );
    }
  }

  get isChildSelected(): boolean {
    return this.selectedChild !== null;
  }

  get hasSelectedChildResults(): boolean {
    return hasAnyResult(this.results);
  }

  activeRouteCommunications(selectedChild: ExtendedChild): string {
    return this.routerLinkFactory.announcementList(selectedChild)[0] + '/' + this.routerLinkFactory.announcementList(selectedChild)[1];
  }

  activeRouteDev(selectedChild: ExtendedChild): string[] {
    return this.routerLinkFactory.dashboard(selectedChild);
  }

  loadScript(): void {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/plugins/sidemenu/sidemenu.js';
    body.appendChild(script);
  }

  menuClick(): void {
    // clearing the clicking functions already present on the element
    $("[data-bs-toggle='slide']").off('click');

    // initiating the click function
    $("[data-bs-toggle='slide']").on('click', function (e) {
      const $this = $(this);
      const checkElement = $this.next();
      const animationSpeed = 300;
      const slideMenuSelector = '.slide-menu';
      if (checkElement.is(slideMenuSelector) && checkElement.is(':visible')) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass('open');
        });
        checkElement.parent('li').removeClass('is-expanded');
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(':visible')) {
        const parent = $this.parents('ul').first();
        const ul = parent.find('ul:visible').slideUp(animationSpeed);
        ul.removeClass('open');
        const parent_li = $this.parent('li');
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass('open');
          parent.find('li.is-expanded').addClass('is-expanded');
          parent_li.addClass('is-expanded');
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });
  }

  openSlide(): void {
    const slideToggle = this.elRef.nativeElement.querySelector('[data-bs-toggle="slide"]');
    const isCommunicationUrl = this.router.url.indexOf('communications') > -1;
    const isDashboardUrl = this.router.url.indexOf('child/dashboard') > -1;
    const openAnnouncements = isCommunicationUrl || isDashboardUrl;
    if (slideToggle && openAnnouncements) {
      slideToggle.click(); // Trigger the click event programmatically
    }
  }

  openAddDialog(): void {
    if (this.children.length === 0) {
      this.modalService.open(ChooseActionComponent);
    } else {
      this.popUp.alert('Vyberte dítě', 'Vyberte, prosím, nejprve profil dítěte.');
    }
  }

  handleClick(e): void {
    e.stopPropagation();
  }

  ngOnDestroy(): void {
    this._subs.forEach((_sub) => _sub.unsubscribe());
  }
}
