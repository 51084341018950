<ng-container *transloco="let t">
  <li class="slide" *ngIf="!selectedChild">
    <a class="side-menu__item" [routerLink]="routerLinkFactory.dashboard(selectedChild)" routerLinkActive="active">
      <i class="sidemenu_icon" [class]="iconService.dashboard"></i>
      <span class="side-menu__label">
        {{ t('child/selectChild') }}
      </span>
    </a>
  </li>

  <!-- Communication and Education -->
  <ng-container *ngIf="selectedChild && communicationPremium$ | async; else education">
    <li class="slide">
      <a
        class="side-menu__item"
        data-bs-toggle="slide"
        [routerLink]="isChildSelected ? activeRouteCommunications(selectedChild) : null"
        [class]="{ pointer: !isChildSelected }"
        (click)="handleClick($event)"
      >
        <i class="sidemenu_icon" [class]="iconService.messageCircle"></i>
        <span class="side-menu__label">{{ t('communications/communicationWithKindergarten') }}</span>
        <i class="angle" [class]="iconService.chevronRight"></i>
      </a>
      <ul class="slide-menu">
        <li>
          <a
            class="slide-item"
            [routerLink]="isChildSelected ? routerLinkFactory.announcementList(selectedChild) : null"
            routerLinkActive="active"
          >
            {{ t('communications/announcement') }}
          </a>
        </li>
        <li>
          <a
            class="slide-item"
            [routerLink]="isChildSelected ? routerLinkFactory.absenceList(selectedChild) : null"
            routerLinkActive="active"
          >
            {{ t('communications/absence') }}
          </a>
        </li>
        <li>
          <a
            class="slide-item"
            [routerLink]="isChildSelected ? routerLinkFactory.childCloseRelative(selectedChild.uuid) : null"
            routerLinkActive="active"
          >
            {{ t('communications/closeRelative') }}
          </a>
        </li>
        <li>
          <a
            class="slide-item"
            [routerLink]="isChildSelected ? routerLinkFactory.childProfile(selectedChild.uuid) : null"
            routerLinkActive="active"
          >
            {{ t('communications/childProfile') }}
          </a>
        </li>
      </ul>
    </li>

    <li class="slide" *ngIf="selectedChild?.kindergarten">
      <a
        class="side-menu__item"
        [class.disabled]="!isChildSelected"
        [routerLink]="isChildSelected ? this.routerLinkFactory.kindergartenResult(this.selectedChild) : null"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
      >
        <i class="fe sidemenu_icon"><img src="assets/img/school.svg" alt="school" /></i>
        <span class="side-menu__label">{{ t('shared/kindergartenResults') }}</span>
      </a>
    </li>

    <li class="slide">
      <a
        class="side-menu__item"
        data-bs-toggle="slide"
        [routerLink]="isChildSelected ? activeRouteDev(selectedChild) : null"
        (click)="handleClick($event)"
      >
        <i class="sidemenu_icon" [class]="iconService.book"></i>
        <span class="side-menu__label">{{ t('shared/devPreSchool') }}</span>
        <i class="angle" [class]="iconService.chevronRight"></i>
      </a>
      <ul class="slide-menu">
        <li>
          <a class="slide-item" [routerLink]="routerLinkFactory.dashboard(selectedChild)" routerLinkActive="active">
            {{ selectedChild?.firstName }}{{ selectedChild?.firstName && ':' }} {{ t('child/dashboard') }}
          </a>
        </li>
        <li>
          <a
            class="slide-item"
            [routerLink]="
              isChildSelected
                ? hasSelectedChildResults
                  ? routerLinkFactory.result(selectedChild)
                  : routerLinkFactory.fillQuestionnaire(selectedChild)
                : null
            "
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            [class]="{ pointer: !isChildSelected }"
            (click)="!isChildSelected && openAddDialog()"
          >
            {{ t('result/overview') }}
          </a>
        </li>
        <li>
          <a class="slide-item" [routerLink]="routerLinkFactory.recommendation()" routerLinkActive="active">
            {{ t('shared/supportAreas') }}
          </a>
        </li>
        <li class="games-slide">
          <a class="slide-item" [routerLink]="this.routerLinkFactory.games()" routerLinkActive="active">
            {{ t('games/elearningGames') }}
          </a>
        </li>
        <li class="games-slide">
          <a class="slide-item" [routerLink]="this.routerLinkFactory.wordGames()" routerLinkActive="active">
            {{ t('games/wordGames') }}
          </a>
        </li>
        <li class="why-slide">
          <a class="slide-item" [routerLink]="routerLinkFactory.whyIsophi()" routerLinkActive="active">
            {{ t('shared/whyISophi') }}
          </a>
        </li>
      </ul>
    </li>

    <li class="slide">
      <a class="side-menu__item" [routerLink]="routerLinkFactory.contact()" routerLinkActive="active">
        <i class="sidemenu_icon" [class]="iconService.send"></i>
        <span class="side-menu__label">{{ t('shared/contact') }}</span>
      </a>
    </li>
  </ng-container>

  <!-- Education only -->
  <ng-template #education>
    <ng-container *ngIf="selectedChild">
      <li class="slide">
        <a class="side-menu__item" [routerLink]="routerLinkFactory.dashboard(selectedChild)" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.dashboard"></i>
          <span class="side-menu__label">
            {{ selectedChild?.firstName }}{{ selectedChild?.firstName && ':' }} {{ t('child/dashboard') }}
          </span>
        </a>
      </li>

      <li class="slide">
        <a
          class="side-menu__item"
          [routerLink]="
            isChildSelected
              ? hasSelectedChildResults
                ? this.routerLinkFactory.result(this.selectedChild)
                : routerLinkFactory.fillQuestionnaire(this.selectedChild)
              : null
          "
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [class]="{ pointer: !isChildSelected }"
          (click)="!isChildSelected && openAddDialog()"
        >
          <i class="sidemenu_icon" [class]="iconService.checkSquare"></i>
          <span class="side-menu__label">{{ t('result/overview') }}</span>
        </a>
      </li>

      <li class="slide" *ngIf="selectedChild?.kindergarten">
        <a
          class="side-menu__item"
          [class.disabled]="!isChildSelected"
          [routerLink]="isChildSelected ? this.routerLinkFactory.kindergartenResult(this.selectedChild) : null"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active"
        >
          <i class="fe sidemenu_icon"><img src="assets/img/school.svg" alt="school" /></i>
          <span class="side-menu__label">{{ t('shared/kindergartenResults') }}</span>
        </a>
      </li>

      <li class="slide">
        <a class="side-menu__item" [routerLink]="this.routerLinkFactory.recommendation()" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.target"></i>
          <span class="side-menu__label">{{ t('shared/supportAreas') }}</span>
        </a>
      </li>

      <li class="slide games-slide">
        <a class="side-menu__item" [routerLink]="this.routerLinkFactory.games()" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.playCircle"></i>
          <span class="side-menu__label">{{ t('games/elearningGames') }}</span>
        </a>
      </li>

      <li class="slide games-slide">
        <a class="side-menu__item" [routerLink]="this.routerLinkFactory.wordGames()" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.type"></i>
          <span class="side-menu__label">{{ t('games/wordGames') }}</span>
        </a>
      </li>

      <li class="slide why-slide">
        <a class="side-menu__item" [routerLink]="routerLinkFactory.whyIsophi()" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.star"></i>
          <span class="side-menu__label">{{ t('shared/whyISophi') }}</span>
        </a>
      </li>

      <li class="slide">
        <a class="side-menu__item" [routerLink]="routerLinkFactory.contact()" routerLinkActive="active">
          <i class="sidemenu_icon" [class]="iconService.send"></i>
          <span class="side-menu__label">{{ t('shared/contact') }}</span>
        </a>
      </li>
    </ng-container>
  </ng-template>
</ng-container>
