/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Category } from '../model/category';
import { EduActivity } from '../model/eduActivity';
import { PaginatedEduActivityList } from '../model/paginatedEduActivityList';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EduActivitiesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * getEduActivities
     * Return all educational activities
     * @param activityType 
     * @param categoryUuid 
     * @param limit 
     * @param offset 
     * @param title 
     * @param uuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEduActivities(activityType?: string, categoryUuid?: Array<string>, limit?: number, offset?: number, title?: string, uuid?: string, observe?: 'body', reportProgress?: boolean): Observable<PaginatedEduActivityList>;
    public getEduActivities(activityType?: string, categoryUuid?: Array<string>, limit?: number, offset?: number, title?: string, uuid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedEduActivityList>>;
    public getEduActivities(activityType?: string, categoryUuid?: Array<string>, limit?: number, offset?: number, title?: string, uuid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedEduActivityList>>;
    public getEduActivities(activityType?: string, categoryUuid?: Array<string>, limit?: number, offset?: number, title?: string, uuid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activityType !== undefined && activityType !== null) {
            queryParameters = queryParameters.set('activity_type', <any>activityType);
        }
        if (categoryUuid) {
            categoryUuid.forEach((element) => {
                queryParameters = queryParameters.append('category_uuid', <any>element);
            })
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (uuid !== undefined && uuid !== null) {
            queryParameters = queryParameters.set('uuid', <any>uuid);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedEduActivityList>('get',`${this.basePath}/edu-activities/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEduActivity
     * Return selected educational activity
     * @param uuid EduActivity UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEduActivity(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<EduActivity>;
    public getEduActivity(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EduActivity>>;
    public getEduActivity(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EduActivity>>;
    public getEduActivity(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getEduActivity.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<EduActivity>('get',`${this.basePath}/edu-activities/${encodeURIComponent(String(uuid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEduActivityCategories
     * Return all categories related to educational activities.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEduActivityCategories(observe?: 'body', reportProgress?: boolean): Observable<Array<Category>>;
    public getEduActivityCategories(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Category>>>;
    public getEduActivityCategories(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Category>>>;
    public getEduActivityCategories(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Category>>('get',`${this.basePath}/edu-activities/categories/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
