<ng-container *transloco="let t">
  <ng-container *ngIf="text">
    <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: showMore }"> </ng-template>
    <button class="btn btn-primary" [style]="{ background: backgroundColor, 'border-color': borderColor }" (click)="showText()">
      {{ showMore ? t('result/showMoreRecommendation') : t('result/showMore') }}
    </button>
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="!text">
    <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: showMore }"></ng-template>
    <button *ngIf="hasPremium && data.length > defaultNumber - 1" class="btn btn-primary" (click)="controlCard(data)">
      <i *ngIf="showMore < defaultNumber" class="fe fe-plus"></i>
      <i *ngIf="!(showMore < defaultNumber)" class="fe fe-minus"></i>
      {{ showMore < defaultNumber ? t('result/showMoreRecommendation') : t('result/showLess1') }}
      <ng-container *ngIf="data.length > showMore"> ({{ data.length - 2 }}) </ng-container>
    </button>
  </ng-container>
</ng-container>
