import { Contract } from '@isophi/parents-api';

export const contractVersion = (c: Contract) => `${c.majorVersion}.${c.minorVersion}`;

export const printableFullText = (c: Contract, placeholderValues: Map<string, string>) => {
  let fullText = c.contractFullText;
  placeholderValues.forEach((value, key) => {
    fullText = fullText.replace(`{{${key}}}`, value);
  });
  return fullText;
};
