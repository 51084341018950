import { Injectable } from '@angular/core';
import { Params, RouterStateSnapshot } from '@angular/router';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  path: string[];
}

@Injectable({
  providedIn: 'root'
})
export class CustomRouterStateSerializer {
  // eslint-disable-next-line class-methods-use-this
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    const path = [];
    let params = {};

    do {
      if (route?.firstChild) {
        route = route.firstChild;
      }
      if (route?.routeConfig?.path) {
        path.push(route.routeConfig?.path);
      }
      if (route?.params) {
        params = { ...params, ...route.params };
      }
    } while (route?.firstChild);

    const {
      url,
      root: { queryParams }
    } = routerState;

    return { url, params, queryParams, path };
  }
}
