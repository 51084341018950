<ng-container *transloco="let t">
  <div class="app-sidebar__overlay" (click)="sidebarClose()"></div>
  <div class="sticky">
    <aside class="app-sidebar">
      <div class="app-sidebar__logo" data-cy-test="sidebar__logo">
        <a class="header-brand" (click)="url()">
          <img src="assets/img/logo/iSophiR_Color.svg" class="header-brand-img desktop-lgo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/iSophiR_Color.svg" class="header-brand-img dark-logo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/R.png" class="header-brand-img mobile-logo" [alt]="t('shared/iSophiR')" />
          <img src="assets/img/logo/R.png" class="header-brand-img darkmobile-logo" [alt]="t('shared/iSophiR')" />
        </a>
      </div>
      <div class="app-sidebar3">
        <div *ngIf="isMobile(); else noMobile" class="main-menu h-100" isophiSidemenuToggle>
          <app-menu *ngIf="loggedUser"></app-menu>
        </div>
        <ng-template #noMobile>
          <div class="main-menu h-100">
            <app-menu *ngIf="loggedUser"></app-menu>
          </div>
        </ng-template>
      </div>
    </aside>
  </div>
</ng-container>
