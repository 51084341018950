<ng-template #content><ng-content></ng-content></ng-template>
<ng-container *ngIf="locked">
  <img *ngIf="previewImage" src="{{ materialServer + previewImage }}" alt="{{ previewTitle }}" />
  <ng-container *ngIf="!previewImage">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>

  <a href="#" class="download" *ngIf="locked" (click)="openBuyLicense($event)">
    <div>Zamčeno</div>
    <div><i aria-hidden="true" class="fe fe-lock"></i></div>
  </a>
</ng-container>
<ng-container *ngIf="!locked">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
