<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      children: children$ | async,
      licenses: licenses$ | async
    } as vm"
  >
    <ul class="side-menu">
      <app-navigation
        class="ms-3"
        [selectedChild]="selectedChild$ | async"
        [results]="results$ | async"
        [children]="vm.children"
      ></app-navigation>
    </ul>
  </ng-container>
</ng-container>
