/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContractAppEnum } from './contractAppEnum';
import { ContractObjectEnum } from './contractObjectEnum';
import { ContractTypeEnum } from './contractTypeEnum';

/**
 * One contract in iSophi system.  Contract can be: - Permission to save child data - Permission to share child results from parent to kindergarten - ...
 */
export interface Contract { 
    id: number;
    /**
     * Application name, where this contract is used. 'none' for general contracts.  * `parents-dashboard` - parents-dashboard * `questionnaire` - questionnaire * `none` - none
     */
    app: ContractAppEnum;
    /**
     * Absolute path in license portal to download file with full version of this contract
     */
    contractFilepath?: string;
    /**
     * Full text of this contract
     */
    contractFullText: string;
    /**
     * Preface of this contract. This can be show to user as 'short version' of contract with 'read more' link
     */
    contractPreface: string;
    /**
     * Read more part of this contract, when preface is rendered to user
     */
    contractReadMore: string;
    /**
     * Contract title showable to user
     */
    contractTitle: string;
    /**
     * Every contract can have multiple versions. True/False if this version is active.
     */
    isVersionActive: boolean;
    /**
     * Programmers keyword to work with contract
     */
    keyword: string;
    /**
     * Major version of this contract
     */
    majorVersion: number;
    /**
     * Minor version of this contract
     */
    minorVersion: number;
    /**
     * If contract consent is required to continue in work
     */
    required: boolean;
    /**
     * All object involved in the contract
     */
    requiredObjects: Array<ContractObjectEnum>;
    /**
     * Type of contract  * `gdpr` - gdpr * `license` - license * `marketing` - marketing
     */
    type: ContractTypeEnum;
}