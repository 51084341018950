<a
  *ngIf="!locked"
  [href]="materialServer + url"
  target="_blank"
  class="btn"
  [class]="{ 'btn-light': worksheetAll, 'btn-primary': !worksheetAll }"
>
  <i aria-hidden="true" class="fe fe-download me-2"></i>{{ title }}
</a>
<a
  *ngIf="locked"
  href="#"
  (click)="openBuyLicense($event)"
  class="btn"
  [class]="{ 'btn-light': worksheetAll, 'btn-primary': !worksheetAll }"
>
  {{ title }} <i aria-hidden="true" class="fe fe-lock"></i>
</a>
