import { Component, OnInit } from '@angular/core';
import { SystemService } from '@isophi/core-legacy';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';
import { MessageService } from './shared/services/message.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public constructor(
    private messageService: MessageService,
    modalConfig: NgbModalConfig,
    private systemService: SystemService,
    private location: Location
  ) {
    modalConfig.size = 'lg';
    this.messageService.initMessageListener();

    if (environment.gtm_id) {
      this.loadGTMScript(environment.gtm_id);
    }
  }

  ngOnInit() {
    // Prevent back-button from crashing android version
    this.initializeApp();
  }

  initializeApp() {
    if (this.systemService.isCordova()) {
      document.addEventListener("deviceready", () => {
        document.addEventListener("backbutton", (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.location.back();
        });
      });
    }
  }

  loadGTMScript(id: string): void {
    const script = document.createElement('script');
    script.innerHTML = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${id}');`;
    script.async = true;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.prepend(noscript);
  }
}
