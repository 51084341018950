/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BlankEnum } from './blankEnum';
import { ChildProfileAddressUpdate } from './childProfileAddressUpdate';
import { Gender38aEnum } from './gender38aEnum';
import { InsuranceCompanyEnum } from './insuranceCompanyEnum';
import { NationalityEnum } from './nationalityEnum';
import { NationalityQualifierEnum } from './nationalityQualifierEnum';
import { NullEnum } from './nullEnum';

export interface ReadExtendedDataChild { 
    firstName: string;
    lastName: string;
    nickname?: string;
    profileImage?: string;
    gender?: Gender38aEnum | BlankEnum | NullEnum;
    birthDate: string;
    birthPlace?: string;
    birthNumber?: string;
    nationalityQualifier?: NationalityQualifierEnum | BlankEnum | NullEnum;
    nationality?: NationalityEnum | BlankEnum | NullEnum;
    importantNote?: string;
    insuranceCompany?: InsuranceCompanyEnum | BlankEnum | NullEnum;
    /**
     * List of addresses. If some address is not present in the list, it will be deleted. If some is present with id, it will be updated. If some is present without id, it will be created.
     */
    addresses?: Array<ChildProfileAddressUpdate>;
    age?: number;
    thumbnail?: string;
}