import { AfterViewInit, Component } from '@angular/core';

const FB_SDK_SCRIPT_ID = 'fb-sdk-script';
const FB_SDK_URL = 'https://connect.facebook.net/cs_CZ/sdk.js#xfbml=1&version=v5.0&appId=2239520213017688&autoLogAppEvents=1';

@Component({
  selector: 'app-facebook-like-plugin',
  templateUrl: './facebook-like-plugin.component.html'
})
export class FacebookLikePluginComponent implements AfterViewInit {
  public ngAfterViewInit() {
    if (document.getElementById(FB_SDK_SCRIPT_ID)) {
      this.renderLikeButton();
      return;
    }

    const firstScriptTag = document.getElementsByTagName('script')[0];
    const scriptTag = document.createElement('script');

    scriptTag.id = FB_SDK_SCRIPT_ID;
    scriptTag.src = FB_SDK_URL;

    scriptTag.setAttribute('crossorigin', 'anonymous');
    scriptTag.setAttribute('async', '');
    scriptTag.setAttribute('defer', '');

    firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
  }

  private renderLikeButton(): boolean {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const fb = window['FB'] || {};
    const xfbml = fb.XFBML;

    if (!xfbml && typeof xfbml.parse !== 'function') {
      return;
    }

    xfbml.parse();
  }
}
