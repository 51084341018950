/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Base class serializer for all OpenAPI serializers.  It only implements create/update method without any logic to prevent \"abstract\" warning by pylint.
 */
export interface DadTest { 
    id: number;
    uuid: string;
    name: string;
    title: string;
    minAge: number;
    maxAge: number;
    age: number;
    version: string;
    etag: string;
    productCodename: string;
}