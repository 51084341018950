/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `dad_test` - dad_test * `questionnaire` - questionnaire
 */
export type ChildResultSourceEnum = 'dad_test' | 'questionnaire';

export const ChildResultSourceEnum = {
    DadTest: 'dad_test' as ChildResultSourceEnum,
    Questionnaire: 'questionnaire' as ChildResultSourceEnum
};