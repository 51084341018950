/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Category } from './category';

/**
 * Page of category list
 */
export interface PaginatedCategoryList { 
    /**
     * Total number of items (in all pages)
     */
    count: number;
    /**
     * URL to next page OR null if there is no next page
     */
    next?: string;
    /**
     * URL to previous page OR null if there is no previous page
     */
    previous?: string;
    results: Array<Category>;
}