import { Injectable } from '@angular/core';
import {
  Child,
  ChildrenService,
  Contract,
  CopyChildProfile,
  CreateChild,
  DadTest,
  ExtendedChild,
  Kindergarten,
  LastResults,
  MaterialsService,
  MergeChildProfile,
  MultipleChildResult,
} from '@isophi/parents-api';
import { BehaviorSubject, concatWith, merge, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ResultMultiType } from '../models/result-type.model';
import { contractVersion } from '../utils/contract.utils';

@Injectable({
  providedIn: 'root',
})
export class ChildService {
  private childrenReload$ = new Subject<void>();

  private children$: Observable<ExtendedChild[]>;

  private selectedChildSubject$ = new BehaviorSubject<Child | null>(null);

  private lastResults$: { [uuid: string]: Observable<LastResults> } = {};

  private selectedChildResults = this.selectedChildSubject$.pipe(
    distinctUntilChanged(),
    // emit null to reset another child's results and then fetch this child's results
    switchMap((child) => (child ? of(null).pipe(concatWith(this.getLastResults(child.uuid))) : of(null)))
    // shareReplay(1)
  );

  public constructor(private childrenApiService: ChildrenService, private materialsApiService: MaterialsService) {}

  public copyProfileFromKindergarten(copyChildProfile: CopyChildProfile): Observable<Child> {
    return this.childrenApiService.copyProfileFromKindergarten(copyChildProfile);
  }

  public createChild(child: Child, contract: Contract, consent: boolean) {
    const { uuid, firstName, lastName, birthDate, gender } = child;
    const createChild: CreateChild = {
      uuid,
      firstName,
      lastName,
      birthDate,
      gender,
      revision: 0,
      childManualRegistrationConsent: consent,
      childManualRegistrationVersion: contractVersion(contract),
    };
    return this.childrenApiService.createChild(createChild);
  }

  public deleteChild(uuid: string): Observable<void> {
    return this.childrenApiService.deleteChild(uuid);
  }

  public getChild(uuid: string): Observable<Child> {
    return this.childrenApiService.getChild(uuid);
  }

  public reloadChildren(reloadSelectedChild?: boolean) {
    this.childrenReload$.next();

    if (reloadSelectedChild) {
      this.children$.subscribe((children) => {
        if (children.length === 1) {
          this.setSelectedChild(children[0]);
        }
      });
    }
  }

  public clear() {
    this.children$ = null;
    this.selectedChildSubject$.next(null);
    this.lastResults$ = {};
  }

  public getChildren(reload = false): Observable<ExtendedChild[]> {
    if (reload) {
      this.reloadChildren();
    }
    if (!this.children$) {
      // emit once on the first subscribe and then whenever childrenReload$ emits
      this.children$ = merge(of(0), this.childrenReload$).pipe(
        switchMap(() => this.childrenApiService.getChildren().pipe(map((response) => response.results))),
        shareReplay(1),
        tap((children) => {
          if (children.length === 1) {
            this.setSelectedChild(children[0]);
          }
        }),
        catchError((error) => {
          console.log(error);
          throw new Error('Unable to download children data.');
        })
      );
    }
    return this.children$;
  }

  public isKindergartenConnected(): Observable<boolean> {
    return this.getChildren().pipe(
      map((children) => {
        return children.some((child) => child.kindergarten?.name);
      })
    );
  }

  /**
   * Get kindergarten data by child-kindergarten copy token.
   *
   * @param code
   */
  public getKindergartenByChildCode(code: string): Observable<Kindergarten> {
    return this.childrenApiService.getKindergartenByCopyToken(code);
  }

  public mergeProfileFromKindergarten(mergeChildProfile: MergeChildProfile): Observable<void> {
    return this.childrenApiService.mergeProfileFromKindergarten(mergeChildProfile);
  }

  public updateChild(child: Child): Observable<Child> {
    return this.childrenApiService.updateChild(child, child.uuid);
  }

  public getLastResults(childUuid: string, reload = false): Observable<LastResults> {
    if (reload) {
      this.lastResults$[childUuid] = null;
    }
    if (!this.lastResults$[childUuid]) {
      this.lastResults$[childUuid] = this.childrenApiService.getLastResults(childUuid).pipe(shareReplay(1));
    }
    return this.lastResults$[childUuid];
  }

  public getResults(resultType: ResultMultiType): Observable<MultipleChildResult[]> {
    return this.selectedChildSubject$.pipe(
      distinctUntilChanged(),
      // emit null to reset another child's results and then fetch this child's results
      switchMap((child) =>
        child
          ? of(null).pipe(
              concatWith(
                this.childrenApiService
                  .getResults(child.uuid)
                  .pipe(map((data) => data.results.filter((result) => result.source === resultType).reverse()))
              )
            )
          : of(null)
      )
    );
  }

  public getResult(datasetUuid: string, childUuid: string): Observable<MultipleChildResult> {
    return this.childrenApiService.getResult(datasetUuid, childUuid);
  }

  public getDadTestData(sourceUuid: string): Observable<DadTest> {
    return this.materialsApiService.getDadTestData(sourceUuid);
  }

  // ##########################################################################
  //  End of API Service wrappers
  // ##########################################################################

  public addChildByCode(
    code: string,
    childCodeRegistrationContract: Contract,
    childCodeRegistrationConsent: boolean,
    userKindergartenSharingContract: Contract,
    kindergarten: Kindergarten
  ): Observable<Child> {
    const data: CopyChildProfile = {
      code,
      childCodeRegistrationConsent,
      childCodeRegistrationVersion: contractVersion(childCodeRegistrationContract),
      userKindergartenSharingConsent: true, // Consent granted by submitting child form.
      userKindergartenSharingVersion: contractVersion(userKindergartenSharingContract),
      userKindergartenSharingKindergartenUuid: kindergarten.uuid,
    };
    return this.copyProfileFromKindergarten(data);
  }

  public mergeProfilesByCode(code: string, childUuid: string): Observable<void> {
    const data: MergeChildProfile = {
      code,
      childUuid,
    };

    return this.mergeProfileFromKindergarten(data);
  }

  /**
   * It map child API errors from code into texts (displayable to user).
   *
   * @param apiErrors   Error array from server.
   * @param appErrors   Array to put in error texts.
   */
  public mapChildApiErrors(apiErrors: Array<{ code: number }>, appErrors: Array<string>): void {
    for (const errorItem of apiErrors) {
      if (errorItem.code === 2502) {
        appErrors.push('Vámi zadaný kód je neplatný.');
      } else if (errorItem.code === 2503) {
        appErrors.push('Vámi zadaný kód již vypršel.');
      } else if (errorItem.code === 1501) {
        appErrors.push('Dítě bylo již přidáno.');
      } else if (errorItem.code === 2002) {
        appErrors.push('Musíte zadat kód dítěte.');
      } else {
        appErrors.push('Vyskytla se neznámá chyba.');
      }
    }
  }

  public setSelectedChild(child: Child) {
    if (this.selectedChildSubject$.value !== child) {
      this.selectedChildSubject$.next(child);
    }
  }

  public setSelectedChildByUuid(uuid: string) {
    if (this.selectedChildSubject$.value?.uuid !== uuid) {
      (uuid ? this.getChildren().pipe(map((children) => children.find((ch) => ch.uuid === uuid))) : of(null)).subscribe(
        (child: ExtendedChild) => {
          this.selectedChildSubject$.next(child);
        }
      );
    }
  }

  public getSelectedChild() {
    return this.selectedChildSubject$.asObservable();
  }

  public getSelectedChildResults(): Observable<LastResults | null> {
    return this.selectedChildResults;
  }

  /**
   * @deprecated Use reactive method `getSelectedChild` instead
   */
  public getSelectedChildValue(): Child {
    return this.selectedChildSubject$.getValue();
  }
}
