/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `legal_representative` - legal_representative * `authorized_person` - authorized_person
 */
export type ChildCloseRelativesStatusEnum = 'legal_representative' | 'authorized_person';

export const ChildCloseRelativesStatusEnum = {
    LegalRepresentative: 'legal_representative' as ChildCloseRelativesStatusEnum,
    AuthorizedPerson: 'authorized_person' as ChildCloseRelativesStatusEnum
};