<form (ngSubmit)="onSubmit()" *transloco="let t">
  <div class="modal-header">
    <h2 class="modal-title">{{ t('user/submitActivationCode') }}</h2>
    <button (click)="activeModal.dismiss(null)" type="button" class="btn-close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label for="activationCode">
        {{ t('user/enterActivationCode') }}
        <a
          [href]="isConnected ? 'https://eshop.isophi.cz/produkt/erko-zvyhodnene' : 'https://eshop.isophi.cz/produkt/erko'"
          target="_blank"
          class="text-decoration-underline text-primary"
          >{{ t('user/enterActivationCodeLink') }}</a
        >.
      </label>
      <div class="input-group">
        <input
          id="activationCode"
          class="form-control"
          [placeholder]="t('user/activationCode')"
          [(ngModel)]="activationCode"
          name="activationCode"
          required
        />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="modal-footer-row">
      <div class="modal-footer-right">
        <button type="submit" class="btn btn-primary btn-lg ga-activation-code" [disabled]="!activationCode">{{ t('user/submit') }}</button>
      </div>
    </div>
  </div>
</form>
