<ng-container *transloco="let t">
  <div
    class="app-header header sticky"
    *ngIf="
      loggedUser && {
        children: children$ | async,
        licenses: licenses$ | async
      } as vm
    "
  >
    <div class="container-fluid main-container">
      <div class="d-flex align-items-center">
        <a class="header-brand" href="index.html">
          <img src="assets/img/logo/iSophiR_Color.svg" class="header-brand-img desktop-lgo" alt="iSophi R" />
          <img src="assets/img/logo/iSophiR_Color.svg" class="header-brand-img dark-logo" alt="iSophi R" />
          <img src="assets/img/logo/R.png" class="header-brand-img mobile-logo" alt="iSophi R" />
          <img src="assets/img/logo/R.png" class="header-brand-img darkmobile-logo" alt="iSophi R" />
        </a>

        <div class="app-sidebar__toggle" isophiSidemenuToggle>
          <a class="open-toggle" href="javascript:;">
            <i [class]="iconService.chevronLeft"></i>
            <i [class]="iconService.menu" class="mobile-icon"></i>
          </a>
          <a class="close-toggle" href="javascript:;">
            <i [class]="iconService.menu"></i>
          </a>
        </div>

        <ng-container *ngIf="isNotQuestionnaireRoute()">
          <app-child-selector
            *ngIf="vm.children?.length"
            class="d-flex align-items-center ms-3"
            [children]="vm.children"
            [selectedChild]="selectedChild$ | async"
          ></app-child-selector>

          <button type="button" *ngIf="username" class="add-child btn nav-link icon ms-4" (click)="openAddDialog()" data-cy-test="addChild">
            <i [class]="iconService.userPlus" aria-hidden="true"></i>
          </button>
        </ng-container>

        <div class="dropdown mobile-header-flags" *ngIf="username">
          <button
            type="button"
            id="manager-menu-profile"
            class="btn nav-link icon"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i [class]="iconService.children" aria-hidden="true"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="manager-menu-profile">
            <div class="dropdown-item disabled">{{ username }}</div>
            <hr class="mb-0" />

            <button
              type="button"
              *ngIf="username"
              class="mobile-add-child dropdown-item btn"
              (click)="openAddDialog()"
              data-cy-test="addChild"
            >
              <i [class]="iconService.userPlus" class="me-2" aria-hidden="true"></i>
              <span>{{ t('child/addChild') }}</span>
            </button>

            <hr class="m-0" />

            <button class="dropdown-item" (click)="onActivationCode()" *ngIf="!vm.licenses?.premium">
              {{ t('shared/applyActivationCode') }}
            </button>
            <a class="dropdown-item" [routerLink]="routerLinkFactory.licenses()" *ngIf="vm.licenses?.premium">{{ t('user/license') }}</a>
            <a class="dropdown-item" data-cy-test="passwordChange" [routerLink]="routerLinkFactory.changePassword()">{{
              t('user/passwordChange')
            }}</a>
            <button class="dropdown-item" (click)="onLogout()" data-cy-test="logOut">{{ t('shared/logOut') }}</button>
          </div>
        </div>

        <div class="d-flex order-lg-2 my-auto ms-auto">
          <div class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
            <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
              <div class="d-flex ms-auto">
                <ng-container *ngIf="username && hidePremium">
                  <ng-container *ngIf="vm.licenses?.premium; else noPremium">
                    <button type="button" class="premium me-4 fw-bold btn btn-secondary" [routerLink]="routerLinkFactory.licenses()">
                      {{ t('shared/premiumActive') }}
                    </button>
                  </ng-container>

                  <ng-template #noPremium>
                    <button type="button" class="premuim me-4 fw-bold btn btn-gray" (click)="openBuyLicense($event)">
                      {{ t('shared/premiumInactive') }}
                    </button>
                  </ng-template>
                </ng-container>

                <isophi-language-picker></isophi-language-picker>

                <div class="dropdown header-flags" *ngIf="username">
                  <button
                    type="button"
                    id="manager-menu-profile"
                    class="btn nav-link icon"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i [class]="iconService.children" aria-hidden="true"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="manager-menu-profile">
                    <div class="dropdown-item disabled">{{ username }}</div>
                    <hr />
                    <button class="dropdown-item" (click)="onActivationCode()" *ngIf="!vm.licenses?.premium">
                      {{ t('shared/applyActivationCode') }}
                    </button>
                    <a class="dropdown-item" [routerLink]="routerLinkFactory.licenses()" *ngIf="vm.licenses?.premium">{{
                      t('user/license')
                    }}</a>
                    <a class="dropdown-item" data-cy-test="passwordChange" [routerLink]="routerLinkFactory.changePassword()">{{
                      t('user/passwordChange')
                    }}</a>
                    <button class="dropdown-item" (click)="onLogout()" data-cy-test="logOut">{{ t('shared/logOut') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
