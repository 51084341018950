import { Injectable } from '@angular/core';
import { CategoriesService, Category } from '@isophi/parents-api';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private categories$: Observable<Category[]>;

  public constructor(private categoriesApiService: CategoriesService) {}

  public getCategories(): Observable<Category[]> {
    if (!this.categories$) {
      this.categories$ = this.categoriesApiService.getCategories().pipe(
        map((response) => response.results),
        shareReplay(1),
        catchError((error) => {
          console.log(error);
          throw new Error('Unable to download categories data.');
        })
      );
    }
    return this.categories$;
  }

  public getCategoryMap(): Observable<{ [uuid: string]: Category }> {
    return this.getCategories().pipe(
      map((categories) => categories.reduce((categoryMap, category) => ({ ...categoryMap, [category.uuid]: category }), {}))
    );
  }
}
