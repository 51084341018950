import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@isophi/core-legacy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Child } from '@isophi/parents-api';
import { combineLatest, Observable } from 'rxjs';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { AccountService } from '../../../core/services/account.service';
import { ChildService } from '../../../core/services/child.service';
import { LicenseService } from '../../../core/services/license.service';
import { ChooseActionComponent } from '../../../modules/child/components/choose-action/choose-action.component';
import { EditLicenseComponent } from '../../../modules/user/components/edit-license/edit-license.component';
import { BuyLicenseComponent } from '../buy-license/buy-license.component';
import { IconService } from '@isophi/core-ui';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() hidePremium: boolean;

  licenses$: Observable<{ premium: boolean }>;

  children$: Observable<Child[]>;

  selectedChild$: Observable<Child>;

  constructor(
    private childService: ChildService,
    public routerLinkFactory: RouterLinkFactory,
    public iconService: IconService,
    private modalService: NgbModal,
    private licenseService: LicenseService,
    private accountService: AccountService,
    private authService: AuthService,
    private router: Router
  ) {
    this.licenses$ = combineLatest({
      premium: this.licenseService.hasPremium(),
    });
    this.children$ = this.childService.getChildren();
    this.selectedChild$ = this.childService.getSelectedChild();
  }

  get username(): string {
    return this.authService.loggedUser?.email;
  }

  get loggedUser(): User {
    return this.authService.loggedUser;
  }

  onActivationCode() {
    this.modalService.open(EditLicenseComponent);
  }

  onLogout() {
    this.accountService.logout();
  }

  isNotQuestionnaireRoute() {
    return !this.router.url.includes('questionnaire');
  }

  openBuyLicense(event) {
    this.modalService.open(BuyLicenseComponent);
    event.preventDefault();
  }

  openAddDialog(): void {
    this.modalService.open(ChooseActionComponent);
  }
}
