<div class="radar-graph" *ngIf="imageArr.length > 0">
  <canvas
    [type]="radarChartType"
    [datasets]="radarChartData"
    [labels]="radarChartLabels"
    [legend]="false"
    [options]="chartOptions"
    [plugins]="plugins"
    baseChart
    [width]="puppeteerApp && '700'"
  ></canvas>
</div>
