import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, IsophiCoreService } from '@isophi/core-legacy';
import { map, Observable } from 'rxjs';
import { Accept_Language } from '../services/recommendation.service';
import { toCamelcaseFun, toSnakecaseFun } from '../utils/object.utils';


const EXCLUDED_URLS = ['/token/', '/logged-user/', '/licenses/', '/parent-reports/questionnaire/'];

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, protected isophiCoreService: IsophiCoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;

    headers = headers.set('app-name', this.isophiCoreService.appKeyword).set('app-version', this.isophiCoreService.appVersion);
    if (!headers.get('Authorization') && this.authService.accessToken) {
      headers = headers.set('Authorization', 'Bearer ' + this.authService.accessToken);
    }

    if (req.context.get(Accept_Language) === 'cs') {
      headers = headers.set('Accept-Language', 'cs');
    }

    const body = req.body !== null && typeof req.body === 'object' && !(req.body instanceof FormData) ? toSnakecaseFun(req.body) : req.body;

    return next.handle(req.clone({ headers, body })).pipe(map(this.convertResponse));
  }

  convertResponse = (event: HttpEvent<any>) => {
    if (event instanceof HttpResponse && event.body && !this.hasExcludedUrl(event.url)) {
      return event.clone({ body: toCamelcaseFun(event.body) });
    }
    return event;
  };

  hasExcludedUrl(url: string) {
    return EXCLUDED_URLS.some((exclUrl) => url.includes(exclUrl));
  }
}
