import { HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EduActivitiesService, EduActivity } from '@isophi/parents-api';
import { forkJoin, map, Observable, shareReplay } from 'rxjs';

import { RecommendationCategory, RecommendationTip } from '../models/recommendation.models';

export const Accept_Language = new HttpContextToken<string>(() => '');

@Injectable({
  providedIn: 'root',
})
export class RecommendationService {
  public constructor(private eduActivitiesApiService: EduActivitiesService) {}

  public getRecommendations(): Observable<RecommendationCategory[]> {
    return forkJoin([
      this.eduActivitiesApiService.getEduActivityCategories('body', false),
      this.eduActivitiesApiService.getEduActivities(),
    ]).pipe(
      map(([categories, eduActivities]) => {
        const eduActivitiesResults = [...eduActivities['results']];

        return categories.map((category) => {
          let categoryExplanation: EduActivity;
          const eduActivitesTips = [];
          const eduActivitesConsultingTips = [];
          const eduActivitesVideo = [];
          let worksheetAll: string;
          let allTipsAudio: string;

          eduActivitiesResults.forEach((eduActivity) => {
            if (eduActivity.categoryUuid === category.uuid) {
              if (eduActivity.activityType === 'category_explanation') {
                categoryExplanation = eduActivity;
              } else if (eduActivity.activityType === 'video') {
                eduActivitesVideo.push(this.eduActivityFactory(eduActivity));
              } else if (eduActivity.activityType === 'tip') {
                eduActivitesConsultingTips.push(this.eduActivityFactory(eduActivity));
              } else if (eduActivity.activityType === 'worksheet') {
                eduActivitesTips.push(this.eduActivityFactory(eduActivity));
              } else if (eduActivity.activityType === 'worksheet_all') {
                worksheetAll = eduActivity.fileUrl;
              } else if (eduActivity.activityType === 'all_tips_audio') {
                allTipsAudio = eduActivity.audioUrl;
              }
            }
          });

          return {
            title: categoryExplanation.title.split(' ').splice(2).join(' '),
            categoryUuid: category.uuid,
            icon: category.activeIconUrl,
            color: category.color,
            description: categoryExplanation.description,
            worksheetAll,
            allTipsAudio,
            headerTips: eduActivitesTips.filter((val) => val.file?.includes('chapter')),
            tips: eduActivitesTips.filter((val) => !val.file?.includes('chapter')).sort((a, b) => a.order - b.order),
            consultingTips: eduActivitesConsultingTips,
            videosPreview: eduActivitesVideo,
          };
        });
      }),
      shareReplay(1)
    );
  }

  private eduActivityFactory(eduActivity: EduActivity): RecommendationTip {
    return {
      textHeader: eduActivity.title,
      textContent: eduActivity.description,
      likes: 0,
      liked: false,
      image: eduActivity.activityType !== 'video' ? eduActivity.imageUrl : null,
      video: eduActivity.video,
      videoPreview: eduActivity.activityType === 'video' ? eduActivity.imageUrl : null,
      file: eduActivity.fileUrl,
      audioUrl: eduActivity.audioUrl,
      link: eduActivity.link,
      order: eduActivity.order,
    };
  }
}
