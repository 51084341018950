/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Range of score that is used during child's result evaluation.
 */
export interface EvaluationRange { 
    readonly id: number;
    /**
     * Code of evaluation range. Every evaluation rule has codes always from 0 increasing by one. So for every rule, range with code 0 is the worst range = worst result, code 1 is better result and so on. Now we usually have 3 ranges with codes 0, 1, 2 for all test/questionnaire rules.
     */
    code: number;
    title: string;
    /**
     * Only present in rule for questionnaire result.
     */
    imageUrl?: string;
    /**
     * Use this color for text, when printing result from this range.
     */
    textColor: string;
    /**
     * Use this color for background of text, when printing result from this range.
     */
    backgroundColor: string;
    /**
     * Min percent to use this range for result. Always there are present only percent fields or point fields.
     */
    minPercent: number;
    /**
     * Max percent to use this range for result. Always there are present only percent fields or point fields.
     */
    maxPercent: number;
    /**
     * Min points to use this range for result. Always there are present only percent fields or point fields.
     */
    minPoint: number;
    /**
     * Max points to use this range for result. Always there are present only percent fields or point fields.
     */
    maxPoint: number;
}