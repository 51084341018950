/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `HL` - Hlavní město Praha * `JM` - Jihomoravský kraj * `JC` - Jihočeský kraj * `KV` - Karlovarský kraj * `VY` - Kraj Vysočina * `KH` - Královéhradecký kraj * `LI` - Liberecký kraj * `MS` - Moravskoslezský kraj * `OL` - Olomoucký kraj * `PA` - Pardubický kraj * `PL` - Plzeňský kraj * `SC` - Středočeský kraj * `US` - Ústecký kraj * `ZL` - Zlínský kraj
 */
export type RegionEnum = 'HL' | 'JM' | 'JC' | 'KV' | 'VY' | 'KH' | 'LI' | 'MS' | 'OL' | 'PA' | 'PL' | 'SC' | 'US' | 'ZL';

export const RegionEnum = {
    HL: 'HL' as RegionEnum,
    JM: 'JM' as RegionEnum,
    JC: 'JC' as RegionEnum,
    KV: 'KV' as RegionEnum,
    VY: 'VY' as RegionEnum,
    KH: 'KH' as RegionEnum,
    LI: 'LI' as RegionEnum,
    MS: 'MS' as RegionEnum,
    OL: 'OL' as RegionEnum,
    PA: 'PA' as RegionEnum,
    PL: 'PL' as RegionEnum,
    SC: 'SC' as RegionEnum,
    US: 'US' as RegionEnum,
    ZL: 'ZL' as RegionEnum
};