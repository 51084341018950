import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  /**
   * Version of the application.
   */
  public version = environment.common.version;

  /**
   * Shortcut of environment type (prod/dev/techsophia/...)
   */
  public envShortcut = environment.envShortcut;
}
