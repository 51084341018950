/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `holiday` - Svátek * `principal_day_off` - Ředitelské volno * `school_holiday` - Prázdniny * `other` - Jiné
 */
export type DayOffTypeEnum = 'holiday' | 'principal_day_off' | 'school_holiday' | 'other';

export const DayOffTypeEnum = {
    Holiday: 'holiday' as DayOffTypeEnum,
    PrincipalDayOff: 'principal_day_off' as DayOffTypeEnum,
    SchoolHoliday: 'school_holiday' as DayOffTypeEnum,
    Other: 'other' as DayOffTypeEnum
};