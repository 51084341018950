import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconService } from '@isophi/core-ui';
import { environment } from '../../../../environments/environment';
import { AndroidHelperService } from '../../services/android-helper.service';

@Component({
  selector: 'app-download-preview',
  templateUrl: './download-preview.component.html',
  styleUrls: ['./download-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadPreviewComponent {
  @Input() url: string;

  @Input() image: string;

  @Input() title: string;

  @Input() locked = false;

  public materialServer = environment.materialServer;

  constructor(
    public iconService: IconService,
    private androidHelperService: AndroidHelperService
  ) {}

  downloadMaterialInAndroid(event: Event, url: string) {
    this.androidHelperService.downloadMaterialInAndroid(event, url);
  }
}
