/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `dad_test` - Dad Test * `dad_test_z` - Dad Test Z * `questionnaire` - Questionnaire * `game` - Game * `smart` - Smart
 */
export type MultipleChildResultSourceEnum = 'dad_test' | 'dad_test_z' | 'questionnaire' | 'game' | 'smart';

export const MultipleChildResultSourceEnum = {
    DadTest: 'dad_test' as MultipleChildResultSourceEnum,
    DadTestZ: 'dad_test_z' as MultipleChildResultSourceEnum,
    Questionnaire: 'questionnaire' as MultipleChildResultSourceEnum,
    Game: 'game' as MultipleChildResultSourceEnum,
    Smart: 'smart' as MultipleChildResultSourceEnum
};