/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AbsenceEnum } from './absenceEnum';
import { AbsenceReasonEnum } from './absenceReasonEnum';
import { BlankEnum } from './blankEnum';
import { NullEnum } from './nullEnum';
import { ParentChildAttendanceCreateOrUpdateSerializersStatusEnum } from './parentChildAttendanceCreateOrUpdateSerializersStatusEnum';

export interface ParentChildAttendanceCreateOrUpdateSerializers { 
    childUuids: Array<string>;
    status: ParentChildAttendanceCreateOrUpdateSerializersStatusEnum;
    absence?: AbsenceEnum | BlankEnum | NullEnum;
    absenceReason?: AbsenceReasonEnum | BlankEnum | NullEnum;
    dateFrom?: string;
    dateTo?: string;
    arrivalTime?: string;
    departureTime?: string;
}