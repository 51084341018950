/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `child` - child * `kindergarten` - kindergarten * `data_set` - data_set
 */
export type ContractObjectEnum = 'child' | 'kindergarten' | 'data_set';

export const ContractObjectEnum = {
    Child: 'child' as ContractObjectEnum,
    Kindergarten: 'kindergarten' as ContractObjectEnum,
    DataSet: 'data_set' as ContractObjectEnum
};