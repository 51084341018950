import { Injectable } from '@angular/core';
import { GaActionEnum, GoogleAnalyticsService } from 'ngx-google-analytics';
import { BriefSubjectDetail } from '../models/brief-subject-detail.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleTrackingService {
  constructor(private gaService: GoogleAnalyticsService) {}

  trackPurchase(isConnected: boolean, licenses: BriefSubjectDetail, activationCode: string) {
    const trackingData = this.preparePurchaseTrackingData(isConnected, licenses, activationCode);

    this.gaService.event(GaActionEnum.PURCHASE, 'Ecommerce', null);
    this.gaService.event(GaActionEnum.PURCHASE, 'Ecommerce', null, null, true, trackingData)
  }

  trackViewProductDetail(isConnected: boolean) {
    const trackingData = this.prepareViewDetailTrackingData(isConnected);

    this.gaService.event(GaActionEnum.VIEW_ITEM, 'Ecommerce', null);
    this.gaService.event(GaActionEnum.VIEW_ITEM, 'Ecommerce', null, null, true, trackingData)
  }

  private preparePurchaseTrackingData(isConnected: boolean, licenses: BriefSubjectDetail, activationCode: string) {
    const validLicense = this.checkExpirationDate(licenses);
    const transactionId = `T_${activationCode}_${Date.now()}`;

    if (isConnected) {

      if (validLicense >= 7) {
        return this.regeneratePurchaseTrackingData(transactionId, 723.72, 151.98, "R-12m-MS", "iSophi R: Zvýhodněné PREMIUM na 12 měsíců", 425.04, 723.72, "VIA_SCHOOL");
      } else {
        return this.regeneratePurchaseTrackingData(transactionId, 411.32, 86, "R-6m-MS", "iSophi R: Zvýhodněné PREMIUM na 6 měsíců", 241, 411.32, "VIA_SCHOOL");
      }

    } else {
      if (validLicense >= 7) {
        return this.regeneratePurchaseTrackingData(transactionId, 1148.76, 241.24, "R-12m", "iSophi R: PREMIUM na 12 měsíců", 0, 1148.76);
      } else {
        return this.regeneratePurchaseTrackingData(transactionId, 652.89, 137.11, "R-6m", "iSophi R: PREMIUM na 6 měsíců", 0, 652.89);
      }
    }
  }

  private prepareViewDetailTrackingData(isConnected: boolean) {
    if (isConnected) {
      return this.regenerateViewDetailTrackingData(723.72, "R-12m-MS", "iSophi R: Zvýhodněné PREMIUM na 12 měsíců", 425.04, 723.72, "VIA_SCHOOL");
    } else {
      return this.regenerateViewDetailTrackingData(652.89, "R-6m", "iSophi R: PREMIUM na 6 měsíců", 0, 652.89);
    }
  }

  private regeneratePurchaseTrackingData(transactionId: string, value: number, tax: number, itemId: string,
    itemName: string, discount: number, price: number, coupon?: string) {
    return {
      transaction_id: transactionId,
      value,
      tax,
      currency: "CZK",
      ...(coupon ? { coupon } : {}),
      items: [
        {
          item_id: itemId,
          item_name: itemName,
          affiliation: "iSophi e-shop",
          ...(coupon ? { coupon } : {}),
          discount,
          index: 0,
          item_brand: "iSophi",
          item_category: "Parents",
          price,
          quantity: 1
        }
      ]
    }
  }

  private regenerateViewDetailTrackingData(value: number, itemID: string, itemName: string, discount: number, price: number, coupon?: string) {
    return {
      value,
      currency: "CZK",
      ...(coupon ? { coupon } : {}),
      items: [
        {
          item_id: itemID,
          item_name: itemName,
          affiliation: "iSophi e-shop",
          ...(coupon ? { coupon } : {}),
          discount,
          index: 0,
          item_brand: "iSophi",
          item_category: "Parents",
          price,
          quantity: 1
        }
      ]
    }
  }

  private checkExpirationDate(licenses: BriefSubjectDetail) {
    const validTo = new Date(licenses.licensePacks[0].validTo);
    const now = new Date();

    return this.dateDiffInMonths(now, validTo);
  }

  private dateDiffInMonths(a: Date, b: Date) {
    const monthsPerYear = 12;
    const monthsDiff = (b.getUTCFullYear() - a.getUTCFullYear()) * monthsPerYear + (b.getUTCMonth() - a.getUTCMonth());

    return monthsDiff;
  }
}
