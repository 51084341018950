import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CategoryResult } from '@isophi/teachers-shared';
import { Chart, ChartData, ChartDataset, ChartOptions, ChartType, Plugin, PluginOptionsByType } from 'chart.js';
import { CategoryResult as pdfCategoryResult } from 'isophi-pdfs-api';

@Component({
  selector: 'isophi-category-doughnut-chart',
  templateUrl: './category-doughnut-chart.component.html',
  styleUrls: ['./category-doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryDoughnutChartComponent implements OnChanges {
  @Input() public categoryResult!: CategoryResult | pdfCategoryResult;

  @Input() public categoryResult2?: pdfCategoryResult;

  @Input() public comparison?: boolean;

  @Input() public puppeteerApp = false;

  public chartData!: ChartData<'doughnut'>;

  public chartType!: ChartType;

  public chartOptions!: ChartOptions<'doughnut'>;

  public plugins: Plugin<'doughnut'>[] = [];

  public evaluations = ['Nízká', 'Snížená', 'Přiměřená'];

  ngOnChanges(): void {
    if (this.categoryResult) {
      this.createDoughnutChart();
    }
  }

  public createDoughnutChart(): void {
    this.chartType = 'doughnut';

    this.chartData = {
      datasets: [
        {
          data: [this.categoryResult.percent, 100 - this.categoryResult.percent],
          backgroundColor: [this.categoryResult.evaluation?.backgroundColor, '#999999'],
          pointBorderColor: ['#999999', '#999999'],
          borderWidth: 0,
        },
      ] as unknown as ChartDataset<'doughnut'>[],
    };

    this.chartOptions = {
      interaction: { mode: null },
      cutout: '75%',
      radius: '75%',
      plugins: {
        'centered_circle': false,
        'number_label': false,
        'polar_outer_categories': false,
        'radar_outer_categories': false,
        datalabels: false
      } as unknown as PluginOptionsByType<'doughnut'>
    };

    if (this.comparison) {
      this.chartData = {
        datasets: [
          {
            data: [this.categoryResult2.percent, 100 - this.categoryResult2.percent],
            backgroundColor: [this.categoryResult2.evaluation.backgroundColor, 'rgb(153, 153, 153, 0.7)'],
            pointBorderColor: ['#999999', '#999999'],
            borderWidth: 1
          },
          {
            data: [this.categoryResult.percent, 100 - this.categoryResult.percent],
            backgroundColor: [this.categoryResult.evaluation.backgroundColor, 'rgb(153, 153, 153, 0.7)'],
            pointBorderColor: ['#999999', '#999999'],
            borderWidth: 1
          }
        ] as unknown as ChartDataset<'doughnut'>[],
      };

      this.chartOptions = {
        interaction: { mode: null },
        cutout: '60%',
        radius: '75%',
        plugins: {
          datalabels: {
            display: false
          }
        }
      };

      this.plugins = [
        {
          id: 'centered_compared',
          afterDraw: (chart: Chart) => {
            const { ctx, height, width } = chart;
            const imageWidth = 30;
            const imageHeight = 40;
            const textX = width / 2;
            const textY = height / 2;
            const xCenter = textX - imageWidth / 2;
            const yCenter = textY - imageHeight / 2;

            const image = new Image();
            const compareData = this.comparisonScore(+this.categoryResult.percent, +this.categoryResult2.percent);
            ctx.font = '18px Merriweather, sans-serif';
            ctx.textAlign = 'center';
            if (+compareData[0] !== 0) {
              image.src = compareData[1];
              ctx.drawImage(image, xCenter - 25, yCenter, imageWidth, imageHeight);

              ctx.fillStyle = compareData[2][0] === '-' ? '#d34e40' : '#00a550';
              const greaterFactor = compareData[2][0] === '-' ? 0 : 5;
              ctx.fillText(compareData[2], textX + 15, textY + greaterFactor);
            } else {
              ctx.fillStyle = '#df9203';
              ctx.fillText(compareData[2], textX, textY + 5);
            }
          },
        },
        {
          id: 'line_compared',
          afterDraw: (chart: any) => {
            const { ctx, _sortedMetasets } = chart;
            _sortedMetasets[0].data.forEach((arc, i: number) => {
              if (i === 0) {
                ctx.font = '15px bold sans-serif';
                ctx.lineWidth = 1;
                ctx.textAlign = 'right';
                ctx.fillStyle = 'black';
                ctx.beginPath();
                ctx.moveTo(arc.x - 50, arc.y + arc.outerRadius - 25);
                ctx.strokeStyle = this.categoryResult2.evaluation.backgroundColor;
                ctx.lineTo(arc.x - 80, arc.y + arc.outerRadius + 20);
                ctx.lineTo(arc.x + arc.outerRadius + 20, arc.y + arc.outerRadius + 20);
                ctx.fillText(
                  `${Math.floor(+this.categoryResult2.percent)} ${this.evaluations[this.categoryResult2.evaluation.code]}`,
                  arc.x + 20,
                  arc.y + arc.outerRadius + 12
                );
                ctx.stroke();
              }
            });
            _sortedMetasets[1].data.forEach((arc, i: number) => {
              if (i === 0) {
                ctx.font = '13px bold sans-serif';
                ctx.lineWidth = 1;
                ctx.textAlign = 'right';
                ctx.fillStyle = 'rgba(0,0,0,0.7)';
                ctx.beginPath();
                ctx.moveTo(arc.x - 45, arc.y - arc.outerRadius + 25);
                ctx.strokeStyle = this.categoryResult.evaluation.backgroundColor;
                ctx.lineTo(arc.x - 80, arc.y - arc.outerRadius - 15);
                ctx.lineTo(arc.x + arc.outerRadius + 20, arc.y - arc.outerRadius - 15);
                ctx.fillText(
                  `${Math.floor(+this.categoryResult.percent)} ${this.evaluations[this.categoryResult.evaluation.code]}`,
                  arc.x + 20,
                  arc.y - arc.outerRadius - 20
                );
                ctx.stroke();
              }
            });
          },
        },
      ];
    } else {
      this.plugins = [
        {
          id: 'centered_percent',
          afterDraw: (chart: Chart) => {
            const { ctx, height, width } = chart;
            const xCenter = width / 2;
            const yCenter = height / 2;
            let fontScale = 10;

            if (+this.categoryResult.percent === 100) {
              fontScale = 20;
            }
            const fontSize = xCenter / 2 - fontScale;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = `bold ${fontSize}px Montserrat, sans-serif`;
            ctx.fillStyle = this.categoryResult.evaluation?.backgroundColor;
            ctx.fillText(`${Math.round(this.categoryResult.percent)} %`, xCenter, yCenter);
          },
        },
        {
          id: 'line_label',
          afterDraw: (chart: any) => {
            const { ctx, _sortedMetasets } = chart;

            _sortedMetasets[0].data.forEach((arc, i: number) => {
              if (i === 0) {
                ctx.font = '13px bold sans-serif';
                ctx.lineWidth = 2;
                ctx.textAlign = 'right';
                ctx.beginPath();
                ctx.moveTo(arc.x - 50, arc.y + arc.outerRadius - 25);
                ctx.lineTo(arc.x - 80, arc.y + arc.outerRadius + 20);
                ctx.lineTo(arc.x + arc.outerRadius + 20, arc.y + arc.outerRadius + 20);

                if (this.puppeteerApp) {
                  ctx.fillText(this.evaluations[this.categoryResult.evaluation.code], arc.x + 20, arc.y + arc.outerRadius + 12);
                } else {
                  ctx.fillText(
                    this.categoryResult.evaluation?.title.substring(0, 27),
                    arc.x + arc.outerRadius + 20,
                    arc.y + arc.outerRadius + 12
                  );
                }

                ctx.strokeStyle = this.categoryResult.evaluation?.backgroundColor;
                ctx.stroke();
              }
            });
          },
        },
      ];
    }
  }

  public comparisonScore(score1: number, score2: number): string[] {
    let score = 0;
    let image = '';
    let text = '';

    if (score1 > score2) {
      score = Math.round(score1 - score2);
      image = 'arrow-down.png';
      text = `-${score}%`;
    } else if (score1 < score2) {
      score = Math.round(score2 - score1);
      image = 'arrow-up.jpg';
      text = `+${score}%`;
    } else {
      score = 0;
      image = '';
      text = `= +${score}%`;
    }
    return [`${score}`, `assets/img/${image}`, text];
  }
}
