/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EvaluationRange } from './evaluationRange';

/**
 * Rule to evaluate child's result.
 */
export interface EvaluationRule { 
    id: number;
    /**
     * Maximum child's age (in days since born) to use this rule. Only present in rule for questionnaire result.
     */
    maxAge?: number;
    /**
     * Minimum child's age (in days since born) to use this rule. Only present in rule for questionnaire result.
     */
    minAge?: number;
    ranges: Array<EvaluationRange>;
}