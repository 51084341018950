/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CopyChildProfile { 
    code: string;
    /**
     * Consent to process child's data, when child is registered by copy code.
     */
    childCodeRegistrationConsent: boolean;
    /**
     * Version of contract for processing child data.
     */
    childCodeRegistrationVersion: string;
    /**
     * Consent to share the user data to child's kindergarten.
     */
    userKindergartenSharingConsent: boolean;
    /**
     * Version of contract for sharing the users data.
     */
    userKindergartenSharingVersion: string;
    /**
     * Kindergarten with which the user shares data.
     */
    userKindergartenSharingKindergartenUuid: string;
}