/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * * `male` - Male * `female` - Female
 */
export type Gender38aEnum = 'male' | 'female';

export const Gender38aEnum = {
    Male: 'male' as Gender38aEnum,
    Female: 'female' as Gender38aEnum
};