/**
 * Isophi portals
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Child } from '../model/child';
import { CopyChildProfile } from '../model/copyChildProfile';
import { CreateChild } from '../model/createChild';
import { ExtendedChild } from '../model/extendedChild';
import { FriendlyErrorMessage } from '../model/friendlyErrorMessage';
import { Kindergarten } from '../model/kindergarten';
import { LastResults } from '../model/lastResults';
import { MergeChildProfile } from '../model/mergeChildProfile';
import { MultipleChildResult } from '../model/multipleChildResult';
import { PaginatedExtendedChildList } from '../model/paginatedExtendedChildList';
import { PaginatedMultipleChildResultList } from '../model/paginatedMultipleChildResultList';
import { PatchedChild } from '../model/patchedChild';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChildrenService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * copyProfileFromKindergarten
     * Copy child&#x27;s profile from kindergarten to logged parent.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProfileFromKindergarten(body: CopyChildProfile, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public copyProfileFromKindergarten(body: CopyChildProfile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public copyProfileFromKindergarten(body: CopyChildProfile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public copyProfileFromKindergarten(body: CopyChildProfile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling copyProfileFromKindergarten.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/copy-profile/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createChild
     * Create new child.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createChild(body: CreateChild, observe?: 'body', reportProgress?: boolean): Observable<Child>;
    public createChild(body: CreateChild, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Child>>;
    public createChild(body: CreateChild, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Child>>;
    public createChild(body: CreateChild, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createChild.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Child>('post',`${this.basePath}/children/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteChild
     * Delete child.
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteChild(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteChild(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteChild(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteChild(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling deleteChild.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChild
     * Return selected child.
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChild(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<ExtendedChild>;
    public getChild(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ExtendedChild>>;
    public getChild(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ExtendedChild>>;
    public getChild(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getChild.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ExtendedChild>('get',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getChildren
     * Return all children.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChildren(observe?: 'body', reportProgress?: boolean): Observable<PaginatedExtendedChildList>;
    public getChildren(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedExtendedChildList>>;
    public getChildren(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedExtendedChildList>>;
    public getChildren(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedExtendedChildList>('get',`${this.basePath}/children/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getKindergartenByCopyToken
     * Return kindergarten associated with copy token.
     * @param code Code to copy child&#x27;s profile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKindergartenByCopyToken(code?: string, observe?: 'body', reportProgress?: boolean): Observable<Kindergarten>;
    public getKindergartenByCopyToken(code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Kindergarten>>;
    public getKindergartenByCopyToken(code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Kindergarten>>;
    public getKindergartenByCopyToken(code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Kindergarten>('get',`${this.basePath}/children/kindergarten-by-copy-token/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getLastResults
     * Download child&#x27;s last result from each source (test, questionnaire, ...)  dad_test is temporarily disabled, that means it is always null for now. (We need to solve sharing data between parents and kindergartens)
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLastResults(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<LastResults>;
    public getLastResults(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LastResults>>;
    public getLastResults(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LastResults>>;
    public getLastResults(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getLastResults.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LastResults>('get',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/last-results/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getResult
     * Return child&#x27;s result detail.
     * @param datasetUuid DataSet UUID
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResult(datasetUuid: string, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<MultipleChildResult>;
    public getResult(datasetUuid: string, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MultipleChildResult>>;
    public getResult(datasetUuid: string, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MultipleChildResult>>;
    public getResult(datasetUuid: string, uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (datasetUuid === null || datasetUuid === undefined) {
            throw new Error('Required parameter datasetUuid was null or undefined when calling getResult.');
        }

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getResult.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MultipleChildResult>('get',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/results/${encodeURIComponent(String(datasetUuid))}/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getResults
     * Return child&#x27;s results.
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResults(uuid: string, observe?: 'body', reportProgress?: boolean): Observable<PaginatedMultipleChildResultList>;
    public getResults(uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PaginatedMultipleChildResultList>>;
    public getResults(uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PaginatedMultipleChildResultList>>;
    public getResults(uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling getResults.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PaginatedMultipleChildResultList>('get',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/results/`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * mergeProfileFromKindergarten
     * Merge child from kindergarten to logged parent.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mergeProfileFromKindergarten(body: MergeChildProfile, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public mergeProfileFromKindergarten(body: MergeChildProfile, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public mergeProfileFromKindergarten(body: MergeChildProfile, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public mergeProfileFromKindergarten(body: MergeChildProfile, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling mergeProfileFromKindergarten.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/children/merge-profile/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * patchUpdateChild
     * Update child - partially.
     * @param uuid Child UUID
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUpdateChild(uuid: string, body?: PatchedChild, observe?: 'body', reportProgress?: boolean): Observable<Child>;
    public patchUpdateChild(uuid: string, body?: PatchedChild, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Child>>;
    public patchUpdateChild(uuid: string, body?: PatchedChild, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Child>>;
    public patchUpdateChild(uuid: string, body?: PatchedChild, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling patchUpdateChild.');
        }


        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Child>('patch',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateChild
     * Update child.
     * @param body 
     * @param uuid Child UUID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateChild(body: Child, uuid: string, observe?: 'body', reportProgress?: boolean): Observable<Child>;
    public updateChild(body: Child, uuid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Child>>;
    public updateChild(body: Child, uuid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Child>>;
    public updateChild(body: Child, uuid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateChild.');
        }

        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling updateChild.');
        }

        let headers = this.defaultHeaders;

        // authentication (isophi-token-auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Child>('put',`${this.basePath}/children/${encodeURIComponent(String(uuid))}/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
