import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddChildComponent } from '../add-child/add-child.component';

enum ActionType {
  MANUAL = 'manual',
  BY_CODE = 'by_code',
}

@Component({
  selector: 'app-choose-action',
  templateUrl: './choose-action.component.html',
  styleUrls: ['./choose-action.component.css']
})

export class ChooseActionComponent {
  public ActionType: typeof ActionType = ActionType;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) {}

  chooseAction(type: string) {
    const modal: NgbModalRef = this.modalService.open(AddChildComponent);
    modal.componentInstance.actionType = type;
    this.activeModal.close();
  }
}
