import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedChild } from '@isophi/parents-api';
import { CustomRouterStateSerializer } from '../../../core/router/custom-router-state-serializer';
import { RouterLinkFactory } from '../../../core/router/router-link.factory';
import { LicenseService } from '../../../core/services/license.service';

@Component({
  selector: 'app-child-selector',
  templateUrl: './child-selector.component.html',
  styleUrls: ['./child-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildSelectorComponent {
  @Input() children: ExtendedChild[];

  @Input() selectedChild: ExtendedChild;

  public constructor(
    public routerLinkFactory: RouterLinkFactory,
    private router: Router,
    private routerSerializer: CustomRouterStateSerializer,
    private licenseService: LicenseService,
  ) {}

  public url(child: ExtendedChild): void {
    const routePath = this.routerSerializer.serialize(this.router.routerState.snapshot).path.join('/');
    this.licenseService.communicationPremium(child.uuid).subscribe((val) => {

      let route = this.routerLinkFactory.dashboard(child);

      if (routePath.includes('kindergarten') && !child.kindergarten) {
        route = this.routerLinkFactory.dashboard(child);
      } else if (routePath.includes('communications') && !val) {
        route = this.routerLinkFactory.dashboard(child);
      } else if (routePath.includes(':childUuid')) {
        route = [routePath.replace(':childUuid', child.uuid)];
      }

      this.router.navigate(route);
    })
  }
}
